import React from 'react';
import './StreakClash.css'

function StreakClash(props) {

    return (
        <div className="widget-default widget-streakclash">
            <h3>{props.teams}, últimos enfrentamientos</h3>
            <React.Fragment >
                <ul>
                    {
                        props.streaks.map((one, index) => {

                            return (
                                <li key={index}>
                                    <div className="widget-streakclash__meta">
                                        <div className="col-6"><span>{one.dateMatchShort}</span></div>
                                        <div className="col-6"><span>{one.tournament}</span></div>
                                    </div>
                                    <div className="widget-streakclash__results">
                                        <div className="match-home">
                                            <span>{one['home'].nameShort}</span>
                                            <img src={one['home'].image} alt="Equipo Local" />
                                        </div>
                                        <div className="match-result"><span className={`clash__result`}>{(one.result) ? one.result : ''}</span></div>
                                        <div className="match-away">
                                            <img src={one['visitor'].image} alt="Equipo Visitante" />
                                            <span>{one['visitor'].nameShort}</span>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </React.Fragment>

        </div>
    )


}

export default StreakClash;