import React  from 'react'
import GroupSearchComponent from '../components/GroupSearch/GroupsSearch'


const GroupsSearch = props => {

  
  return (<GroupSearchComponent onGroupRequest={()=>{}}/> )
  

}

export default GroupsSearch