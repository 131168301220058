import React, { useState, useEffect } from 'react';
import { getForecastGroup } from '../store/actions/forecastgroup'
import { useSelector, useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router';
import { Container, Row, Col, Spinner } from 'reactstrap';

import DeathMatch from '../components/ForescastDeathMatch/DeathMatch';


const ForescastDeathMatch = props => {
  const [loading, setloading] = useState(true);
  const [IdMatch, setIdMatch] = useState(true);

  const { history, location } = useReactRouter();
  const reducer = useSelector(state => {
    return {
      ForecastGroup: state.forecastgroup,
      userId: state.user.id
    }

  })

  const dispatch = useDispatch()

  useEffect(() => {

    let executeFunc = async () => {
      try {
        if (!location.state) {
          history.push('/pronosticos')
        }
        setloading(true);
        setIdMatch(location.state.matchId)
        setloading(true);

        dispatch(getForecastGroup({ idMatch: location.state.matchId }))
        // setdataLoad(produc.result);

        setloading(false);
      } catch (error) {
        setloading(false);

        console.log(error);
      }
    }
    executeFunc();
    // eslint-disable-next-line
  }, [])


  if (loading) return <Spinner style={{ width: '3rem', height: '3rem' }} />

  if (reducer.ForecastGroup.loading || !reducer.ForecastGroup.matches) {
    return <Spinner style={{ width: '3rem', height: '3rem' }} />
  } else {


    // setmyselector(sel);
    return (
      <React.Fragment >
        {(reducer.ForecastGroup.matches) ?
          <Container className="match-component">
            <Row>
              <Col>
                <DeathMatch match={reducer.ForecastGroup.matches[`match${IdMatch}`]} userId={reducer.userId} />
              </Col>
            </Row>
          </Container>
          :
          ''
        }
      </React.Fragment >
    )
  }



}

export default ForescastDeathMatch;