import React, { useEffect, useState } from 'react'
import api from '../../api'

import { Button, Input, InputGroup, InputGroupAddon, Container, Col, Row } from 'reactstrap'
import GroupList from '../GroupList/GroupList'
import { useSelector, useDispatch } from 'react-redux'
import { FaSearch, FaTimesCircle } from "react-icons/fa";

import { validationHandleError, customError } from '../../store/actions/utils'
import { updRequest } from '../../store/actions/user'

import './GroupsSearch.css';


const GroupsSearch = props => {

  const [inputGroup, setInputGroup] = useState('')
  const [groupList, setGroupList] = useState([])
  const [typingTimeout, setTypingTimeout] = useState(true)
  const [message, setMessage] = useState(false)
  const [error, setError] = useState(false)
  const dispatch = useDispatch()

  const objReducer = useSelector(state => {
    return {
      idUser: state.user.id,
      groups: state.user.groups
    }
  })

  useEffect(() => {
    if (typingTimeout) {
      searchGroupHandler()
      setTypingTimeout(false)
    }
    // eslint-disable-next-line
  }, [typingTimeout])


  const changeHandler = (e) => {
    if (e.target.value[0] === '#' && e.target.value.length < 7) {
      setInputGroup(e.target.value)
    } else {
      setInputGroup(e.target.value)

      setTimeout(function () {
        setTypingTimeout(true)
      }, 500)
    }

  }

  const cleanHandler = () => {
    setInputGroup('')
    setTypingTimeout(true)
  }

  const searchGroupHandler = async () => {
    try {
      let group = false
      if (inputGroup.substring(0, 1) === "#") {
        group = objReducer.groups.find(item => item.token === inputGroup)
        if (group) {
          let userMessage = group.user_status === 'Aceptar' ? 'Ya estas Dentro del Grupo' : 'Ya tienes una solicitude pendiente del Grupo'
          group.message = userMessage
          group.btnDisable = true
          setGroupList([group])
          setMessage(false)

        }
      }

      if (!group) {

        let data = await api.groups.find(encodeURIComponent(inputGroup))
        if (data.statusCode !== 200 && data.statusCode !== 409) {
          customError.prototype = Error.prototype;
          const message = (data.message || data.result.message)
          // eslint-disable-next-line
          throw { name: (data.error || ''), message: message, status: data.status };
        }

        if (data.status) {
          setMessage(false)
          if (Array.isArray(data.result)) {
            setGroupList(data.result)
          } else {
            let dataNew = []
            dataNew.push(data.result)
            setGroupList(dataNew)
          }
        } else {
          setGroupList([])
          setMessage('No encontramos grupos')
        }
      }
    } catch (e) {
      validationHandleError(e)
      setError({ hasError: true, name: e.name, message: e.message, status: e.status })
    }

  }

  const groupClickHandler = async (idGroup, index) => {
    try {
      let data = await api.groups.join({ id_user: objReducer.idUser }, idGroup)
      if (data.statusCode !== 200 && data.statusCode !== 201) {
        customError.prototype = Error.prototype;
        const message = (data.message || data.result.message)
        // eslint-disable-next-line
        throw { name: (data.error || ''), message: message, status: data.status };
      }
      if (data.status) {
        let newGroups = [...groupList]
        newGroups[index].message = "Solicitud enviada"
        newGroups[index].btnDisable = true
        setGroupList(newGroups)
        setMessage('')
        dispatch(updRequest({ groupRequest: true }))
        if (props.onGroupRequest) {
          props.onGroupRequest()
        }
      }
    } catch (e) {
      validationHandleError(e)
      setError({ hasError: true, name: e.name, message: e.message, status: e.status })
    }
  }

  let messageDiv = null;

  if (message) {
    messageDiv = <p className="groupsearch-info">{message}</p>
  }

  if (error) {
    // messageDiv =  <Alert color="danger"> Tuvimos un Problema {error.message}</Alert>
    messageDiv = <p className="groupsearch-info danger">Tuvimos un Problema</p>
  }

  let iconDetail = <FaSearch className="display-list" />
  let buttonAction = <Button onClick={searchGroupHandler} className="groupsearch-searchicon">{iconDetail}</Button>
  if (inputGroup.length > 0) {
    iconDetail = <FaTimesCircle onClick={cleanHandler} />
    buttonAction = <Button onClick={undefined} className="groupsearch-searchicon">{iconDetail}</Button>
  }

  return (
    <Container className="groups-container groupsearch-container">
      <Row>
        <Col>
          <InputGroup>
            <Input type="text" name="group" id="group" placeholder="Ingresá unas letras" value={inputGroup} onChange={changeHandler} />
            <InputGroupAddon addonType="append">{buttonAction}</InputGroupAddon>
          </InputGroup>
          <GroupList data={groupList}
            id="id_group"
            defaultText=""
            itemName="group"
            btnType='NoIcon'
            title=""
            clickHandler={groupClickHandler}
            detail={[{ key: 'owner', value: 'owner' }]}
          />
          {messageDiv}
        </Col>
      </Row>
    </Container>
  )

}

GroupsSearch.defaultProps = {
  onGroupRequest: false
}

export default GroupsSearch