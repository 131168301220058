import React from 'react'
import { Spinner, Button } from 'reactstrap';
import './UserNotification.css'

import { FaCheck, FaTimes } from "react-icons/fa";
import Swiper from 'react-id-swiper';


const UserNotification = props => {
  let description = (props.description || 'Quiere unirse a ')

  const params = {
    slidesPerView: 'auto',
    spaceBetween: 30,
  }


  if (props.loading) {
    return (<Spinner className="spinner-stats" color="dark" size="sm" />)
  }
  // console.log(`aca estan las propitas`, props)
  let bodyMessage = <span className="span-info">No hay novedades</span>
  if (props.data.length > 0)
    bodyMessage = (
      <Swiper {...params}>
        {props.data.map((notification, index) =>
          <div key={index} className="card_container">
            <div className="card_title">
              <p>{notification.username}</p>
              <span>{description}<strong>{notification.group}</strong></span>
            </div>
            <div className="card_btn_container">
              <Button color="danger" onClick={() => props.clickHandler(notification, true)}><FaTimes className="display-list" /></Button>
              <Button color="success" onClick={() => props.clickHandler(notification, false)}><FaCheck className="display-list" /></Button>
            </div>
          </div>
        )}
      </Swiper>
    )

  return (
    <div className="gridlist_container user-news">
      <h6 className='invites-pending'>Novedades</h6>
      {bodyMessage}
    </div>
  )


}

UserNotification.defaultProps = {
  loading: false
}

export default UserNotification