import Cookies from 'js-cookie';

export const checkValidity = (value, rules) => {
  let isValid = true
  if (!rules) {
    return true;
  }
  
  if (rules.required) {
      isValid = value.trim() !== '' && isValid;
  }

  if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid
  }

  if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid
  }

  if (rules.isEmail) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid
  }

  if (rules.isNumeric) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid
  }

  return isValid;
}

export const _GET = (name = '') => {
    var params = new URLSearchParams(window.location.search);
    let response
    if (name) {
        response = params.get(name);
    } else {
        let full_params = []
        let search = window.location.search.split('&')
        
        if (search.length > 1) {
            // eslint-disable-next-line
            search.map((one, index) => {
                let _split = one.split('=')
                if (index === 0)
                    full_params[_split[0].substr(1)] = _split[1]
                else
                    full_params[_split[0]] = _split[1]
            })
        } else {
            full_params = null
        }
        response = full_params
    }
    return response

}

export const EventListenerIframe = (datetime) => {
    return new Promise((resolve, reject) => {

        // Secrea un contador de 4 segundos, si en ese tiempo la respuesta(firebaseId) no ha llegado entonces se rechaza la promesa
        var wait = setTimeout(() => {
            window.setTimeout(wait);
            // reject('Nunca llego firebaseId!');
            let noFireBaseIdtimestamp = `sinFireBaseIdorWeb_${Date.now()}`
            resolve(noFireBaseIdtimestamp)
        }, 4000)

        PostMessagefromiframe(JSON.stringify({
            status: true,
            statusCode: 200,
            msg: 'online'
        }))

        function bindEvent(element, eventName, eventHandler) {
            if (element.addEventListener) {
                element.addEventListener(eventName, eventHandler, false);
            } else if (element.attachEvent) {
                element.attachEvent('on' + eventName, eventHandler);
            }
        }
        bindEvent(window, 'message', function (e) {
            try {
                if (JSON.parse(e.data).firebaseId){        
                    clearTimeout(wait);        
                    resolve(JSON.parse(e.data).firebaseId)
                }else{
                   
                }
            } catch (error) {
                console.log(error);
            }
        })
        

    });
}

export const getToken = () => {
    const token = Cookies.get('token');
    let local_t = localStorage.getItem('_t')

    if (token){
        return token
    } else if (local_t){
        return local_t
    }
    return null
 
}

export const removeToken = () => {
    try {
        Cookies.remove('token')
        localStorage.removeItem('_t');
    } catch (error) {
        console.log('error al tratar de remover el token del localstoarge o cookie');
    }
}

export const PostMessagefromiframe = (message) => {

    try {
        window.parent.postMessage(message, '*');
    } catch (e) {
        console.log('Fall a enviando mensaje');
        // return e
    }

    return [
        PostMessagefromiframe
    ]
}