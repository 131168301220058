import {
  UPDATE_MATCH, 
  GET_TOURNAMENT, 
  ADD_MATCH, 
  LOADING,
  SINGLE_MATCH_UPDATE, 
  ERROR, 
  ERROR_UPDATE 
} from '../types/tournamentTypes'

const INITIAL_STATE = {
  tours: {},
  stages: {},
  loading: false,
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return { 
        ...state, 
        loading: true 
      }
    case ERROR:
      return { 
        ...state, 
        error: action.payload,
        loading: false
      }
    case ERROR_UPDATE:
      let toUpdateStage_e = { ...state.stages[`stage${action.payload.id_tournament}_${action.payload.current_instance}`] }
      let indexToChange_e = toUpdateStage_e.matches.findIndex(x => x.id_match === parseInt(action.payload.idMatch))
      toUpdateStage_e.matches[indexToChange_e].error = action.payload.error
      return { 
        ...state, 
        error: action.payload,
        loading: false
      }
    case ADD_MATCH: 
      return {
        ...state,
        stages:{
          ...state.stages, 
          [`stage${action.payload.id_tournament}_${action.payload.current_instance}`] : action.payload
        }, 
        loading:false
      }
    case GET_TOURNAMENT: 
      return {
        ...state,
        tours: action.payload,
        loading:false
      }
    case UPDATE_MATCH: 
      let toUpdateStage = { ...state.stages[`stage${action.payload.id_tournament}_${action.payload.current_instance}`]}
      let indexToChange = toUpdateStage.matches.findIndex(x => x.id_match === parseInt(action.payload.idMatch))
      toUpdateStage.matches[indexToChange]['user_result'] = `${action.payload.forecast.home} - ${action.payload.forecast.away}`
      toUpdateStage.matches[indexToChange].loading = false
      return {
        ...state,
        stages: {
          ...state.stages,
          [`stage${action.payload.id_tournament}_${action.payload.current_instance}`]: toUpdateStage
        }, 
        loading:false
      }
    case SINGLE_MATCH_UPDATE: 
      let toUpdateStage2 = { ...state.stages[`stage${action.payload.id_tournament}_${action.payload.current_instance}`]}

      let indexToChange2 = toUpdateStage2.matches.findIndex(x => x.id_match === parseInt(action.payload.idMatch))
      toUpdateStage2.matches[indexToChange2].loading = true
      return {
        ...state,
        stages: {
          ...state.stages,
          [`stage${action.payload.id_tournament}_${action.payload.current_instance}`]: toUpdateStage2
        }, 
        loading:false
      }
    
    default: return state;
  }
}