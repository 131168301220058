import React from 'react'


const LoginForm = props => {

  // const handleClick = e => {
  //   // console.log('Button was clicked');
  // }

  let btnName = 'Iniciar Sesión'
  let btnCrearClass = '';
  let btnLoginClass = '';
  let mainTitle = '¿Ya tenés cuenta?';
  let title = null;
  if(props.createFlag){
    btnCrearClass = 'button-active'
    btnLoginClass = 'button-disabled'
    mainTitle = '¿Crea una cuenta?';
  }else{
    btnCrearClass = 'button-disabled'
    btnLoginClass = 'button-active'
  }
  let btnCrear = (
    <React.Fragment>
      <button type="submit" className={`button-signup ${btnCrearClass}`} onClick={props.signupHandler}>Crear nueva cuenta</button>
    </React.Fragment>
  )
  let emailInput = (
    <div className="login-form__email">
      <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
      <input id="email" type="text" className="form-control" autoComplete="email" name="email" placeholder="E-mail" value={props.formValues.email.value} onChange={props.onChange} />
    </div>
  )
  let thirdPart = null;
  let secondPart = (
    <React.Fragment>
      <div className="login-form__password">
        <span className="input-group-addon"><i className="fa fa-lock"></i></span>
        <input id="password" type="password" className="form-control" name="password" placeholder="Contraseña" autoComplete="current-password" value={props.formValues.password.value} onChange={props.onChange} />
      </div>
      <div className="login-form__checkbox">
        <div className="form-check" >
          <input type="checkbox" className="form-check-input" id="rememberme" onChange={(event) => props.rememberHandler(event)}
            checked={props.remember}
          />
          <label className="form-check-label" htmlFor="rememberme" >
            Recordame
          </label>
        </div>
        <p className="login-form__forgotten" onClick={props.forgotPassHandler} >
          Olvidé mi contraseña
        </p>
      </div>
    </React.Fragment>
  )

  let formTitle = (<p>{mainTitle}</p>);

  // let externalAuth = (
  //   <div className="login-form__signin">
  //     <div className="gmail">
  //       <a href="https://prode.api.playtown.com.ar/prode/auth/google/">
  //         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path d="M2 0h36c1.1 0 2 .9 2 2v36c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0z" fill="#4285f4" /><path d="M2 1h36a.94.94 0 0 1 1 1v36a.94.94 0 0 1-1 1H2a.94.94 0 0 1-1-1V2a.94.94 0 0 1 1-1z" fill="#fff" /><path d="M28.6 20.2c0-.6-.1-1.3-.2-1.8H20v3.5h4.8c-.2 1.1-.8 2-1.8 2.7v2.3h3c1.7-1.6 2.6-4 2.6-6.7z" fill="#4285f4" /><path d="M20 29c2.4 0 4.5-.8 6-2.2l-3-2.2c-.8.5-1.8.9-3 .9-2.3 0-4.3-1.6-5-3.7h-3V24c1.4 3 4.5 5 8 5z" fill="#34a853" /><path d="M15 21.7c-.2-.5-.3-1.1-.3-1.7s.1-1.2.3-1.7V16h-3c-.6 1.2-1 2.6-1 4s.3 2.8 1 4l3-2.3z" fill="#fbbc05" /><path d="M20 14.6c1.3 0 2.5.5 3.4 1.3l2.6-2.6c-1.5-1.4-3.6-2.3-6-2.3-3.5 0-6.6 2-8 5l3 2.3c.7-2.1 2.7-3.7 5-3.7z" fill="#ea4335" /></svg>
  //         Iniciar sesión con Google
  //         </a>
  //     </div>
  //     <div className="facebook">
  //       <a href="https://prode.api.playtown.com.ar/prode/auth/facebook/">
  //         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.8 90.7 226.4 209.2 245V327.7h-63V256h63v-54.6c0-62.2 37-96.5 93.7-96.5 27.1 0 55.5 4.8 55.5 4.8v61h-31.3c-30.8 0-40.4 19.1-40.4 38.7V256h68.8l-11 71.7h-57.8V501C413.3 482.4 504 379.8 504 256z" fill="#fff" /></svg>
  //         Iniciar sesión con Facebook
  //       </a>
  //     </div>
  //   </div>)

  if (props.type === 'forgot') {
    formTitle = (<p>Por favor ingresá el e-mail registrado.</p>)
    btnName = 'Recuperar'
    btnCrear = null
    title = null
    secondPart = null;
    // externalAuth = null;
    thirdPart = (
      <div className="login-form__infobtn" >
        <button onClick={props.forgotPassHandler} className="button-off">
          Volver atrás
        </button>
      </div>
    )
  }

  if (props.error) {
    title = (
      <span className="login-form__info">
        {props.error.message}
      </span>
    )
  }


  return (
    <div className="login-form">
      <form onSubmit={props.onSubmit}>

        <div className="login-form__account">
          {formTitle}
          {emailInput}
          {secondPart}
          {title}
        </div>
        <div className="login-form__account-login">
          <button type="submit" className={`button-login ${btnLoginClass}`} >{btnName}</button>
          {btnCrear}
        </div>
        {thirdPart}
      </form>
    </div>
  );
}

LoginForm.defaultProps = {
  type: 'login'
}

export default LoginForm;