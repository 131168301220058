import React, { useState } from 'react';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux'

import { Container, Button, Row} from 'reactstrap'
import GroupSearchComponent from '../components/GroupSearch/GroupsSearch'
import GroupCreateComponente from '../components/GroupCreate/GroupCreate'
import { setSession } from '../store/actions/user'

import api from '../api';

import './styles/Wizard.css';

  const Wizard = props => {


    const [step, setstep] = useState(0);
    const [selector, setselector] = useState(0);
    const [message, setMessage] = useState(false)
    // const [error, setError] = useState(false)
    const dispatch = useDispatch()

    const objReducer = useSelector(state => {
      return {
        user: state.user
      }
    })
    const { history } = useReactRouter();


    function changeWizard (action,next) {
      setselector(action)
      setstep(next)
    }

    function submitGroupWizard (type) {
      // eslint-disable-next-line
      switch (type) {
        case 'creado':
          setMessage('Felicidades, Creaste un nuevo grupo!')
          break;
        case 'buscado':
          setMessage('Felicidades 💪')
          break;
      }
      setTimeout(function () {
        changeWizard('', 2)
      }, 2000)
    }

    const resetwizard = () => {
      changeWizard('', 0)
    }

    const finishWizard = async () => {
      
      try {
        let data = await api.user.update(objReducer.user.id, { field_update: 'wizard', value_update: '0' })
        if (data.status !== 200) {
          console.log("Tuvimos un problema para actualizar el user" )
        } else {
          dispatch(setSession(data.result))
        }
        history.push('/pronosticos')
      }
      catch (e) {
        console.log("Tuvimos un problema para actualizar el perfil" )
      }

    }

    let messageDiv = null;

    if (message) {
      messageDiv = 
      <React.Fragment>
      <h6 className="group-created"> {message}</h6>
      <p className="group-created">Ahora sólo resta sumar puntos!</p>
      </React.Fragment>
    }

    // if (error) {
    //   messageDiv = <Alert color="danger"> Tuvimos un Problema {error.message}</Alert>
    // }
      // if(objReducer.error){
    //   errorMessage =  <Alert color="danger"> Tuvimos un Problema</Alert>
    // }
    var showWizard;
    var classWizardStep;
    // eslint-disable-next-line
    switch (step) {
      case 0:
        classWizardStep = 'selection-buttons'
        showWizard = (
          <React.Fragment>
            <p className="wizard-description">Podés crear o unirte a un grupo para poder empezar a pronosticar.</p>
            <Button className="choice" color="primary" size="lg" block onClick={() => changeWizard('buscar',1)}>
            <img src="search-group.png" alt="logo" />
            Buscar grupo
            </Button>
            <Button className="choice" color="primary" size="lg" block onClick={() => changeWizard('crear',1)}>
            <img src="create-group.png" alt="logo" />
              Crear grupo
              </Button>
          </React.Fragment>
        )
        break;

      case 1:
          if (selector === 'crear'){
            classWizardStep = 'group-create'
            showWizard = (
              <React.Fragment>
                  <GroupCreateComponente onGroupCreated={() => submitGroupWizard('creado')} />
                  <Button className="tournament-card__button" color="primary" size="lg" onClick={resetwizard}>Atrás</Button>
              </React.Fragment>
            )
        }
        if (selector === 'buscar'){
          classWizardStep = 'group-search'
          showWizard = (
            <React.Fragment>
              <GroupSearchComponent onGroupRequest={() => submitGroupWizard('buscado')} />
              <Button className="tournament-card__button" color="primary" size="lg" onClick={resetwizard}>Atrás</Button>
            </React.Fragment>
          )
        }
        break;
      case 2:
        showWizard = (
          <React.Fragment>
            {messageDiv}
            <img className="item_1 demo_wizard" src="demo.gif" alt="demo"/>
            <Button className="tournament-card__button tournament-card__button-final" color="primary" size="lg" block onClick={finishWizard}>Empezar a pronosticar</Button>
          </React.Fragment>
        )
        break;
    }


      return (
        <React.Fragment>

<Container className={`wizard-container ${classWizardStep}`} style={{ backgroundImage: `url("header-background.png")` }}>
        <Row className="justify-content-lg-center">
          <div className="wizard-step col-lg-8">
            <div className="header-brand">
              <img src="brand_192x192.png" alt="logo" />
            </div>
            {showWizard}
          </div>
        </Row>
      </Container>

        </React.Fragment>
    )
}

export default Wizard;