import React, {useEffect} from 'react'
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux'

import UserNotificationGroup from '../components/UserNotification/UserNotification'
import GroupList from '../components/GroupList/GroupList'

import {Alert, Card, CardBody, Container, Row, Col} from 'reactstrap'

import {getTournaments} from '../store/actions/tournament'
import {getUserGroups, approvalHandler, getUserNotifications, leaveGroup} from '../store/actions/user'
import './styles/Groups.css';


const Groups = props => {

  const { history } = useReactRouter();
  const dispatch = useDispatch()

  const objReducer = useSelector(state => {
    return {
      groups: state.user.groups, 
      notifications: state.user.notifications, 
      loading: state.user.loading,
      error: state.user.error}
  })

  //Llamaos al reducer en el inicio para ver si nos falta la info
  useEffect(() => {
    dispatch(getUserGroups())
    dispatch(getTournaments())
    dispatch(getUserNotifications('groups'))
  }, [dispatch])

  const searchGroupHandler = () => {
    history.push('/grupos/busqueda') 
  }

  const createGroupHandler = () =>{
    history.push('/grupos/crear') 
  }

  const clickHandler = (_obj, shouldReject) =>{
    if(shouldReject){
      //Enpoint para rechazar invite
      dispatch(approvalHandler({..._obj, approvalStatus:3}))
    }else{
      //Enpoint para aceptar invite
      dispatch(approvalHandler({..._obj, approvalStatus:2}))
    }
  }

  const groupClickHandler = (id, index) => {
    const group = objReducer.groups.find(group => group.id_group === id)
    history.push(`/grupos/clasificacion/${group.group.split(' ').join('__')}`, { idGroup: id, title: group.group })
  }

  const leaveGroupHandler = (_obj) => {
    dispatch(leaveGroup({..._obj, approvalStatus:4}))
  } 

  let errorMessage = null;
  if(objReducer.error){
    errorMessage =  <Alert color="danger"> Tuvimos un Problema</Alert>
  }
  
  
  return (
    <Container className="groups-container">
      <Row>
        <Col>
      <div className="groups-search-create">
      <Card className="search-groups" onClick={searchGroupHandler}>
        <CardBody>
        <img src="search-group.png" alt="logo" />
          Encontrá tu grupo
        </CardBody>
      </Card>
      <Card className="search-groups" onClick={createGroupHandler}>
        <CardBody>
          Crear Grupo
        </CardBody>
      </Card>
      </div>
      <UserNotificationGroup data={objReducer.notifications.groups.pendings} loading={objReducer.loading} clickHandler={clickHandler}/>
      <GroupList 
        data={objReducer.groups.filter(group => group.user_status === 'Aceptar')} 
        loading={objReducer.loading} 
        id="id_group" 
        itemName="group" 
        title="Tus grupos" 
        clickHandler={groupClickHandler}
        message="No perteneces a ningun grupo"
        detail={[{key:'token', value:'token'},{key:'owner', value:'owner'}]}
      />
      <GroupList 
        data={objReducer.groups.filter(group => group.user_status === 'Pendiente')}
        loading={objReducer.loading}
        id="id_group"
        itemName="group"
        title="Solicitudes enviadas"
        clickHandler={groupClickHandler}
        btnOnClick={leaveGroupHandler} 
        btnType="FaTrash"
        message="No tienes solicitudes enviadas"
        detail={[{key:'token', value:'token'},{key:'owner', value:'owner'}]}
      />
      {errorMessage}
      </Col>
      </Row>
    </Container>
  )

}

export default Groups