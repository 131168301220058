import React, {useEffect} from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

//Components
import Layout from './Layout'
import Home from '../pages/Home'
import Wizard from '../pages/Wizard'
import Forecast from '../pages/Forecast'
import ForescastGroup from '../pages/ForescastGroup'
import ForescastHelp from '../pages/ForescastHelp'
import ForescastDeathMatch from '../pages/ForescastDeathMatch'
import Ranking from '../pages/Ranking'
import Groups from '../pages/Groups'
import GroupsSearch from '../pages/GroupsSearch'
import GroupsCreate from '../pages/GroupsCreate'
import OutstandingGroups from '../pages/OutstandingGroups'
import Tournaments from '../pages/Tournaments'
import Login from '../pages/Login'
import Profile from '../pages/Profile'
import Help from '../pages/Help'
import ScrollToTop from '../components/ScrollToTop'

//Action Reducer
import { getUserGroups, setSession, checkSession, logout } from '../store/actions/user'
import { getTournaments } from '../store/actions/tournament'
import { getToken } from '../utils/utils';


const App = props => {
  var jwt = require('jsonwebtoken');
  const dispatch = useDispatch()

  const userReducer = useSelector(state => {
    
    return {isLoggedIn: state.user.isLoggedIn, userName: state.user.name, sessionChecked: state.user.sessionChecked }
  })
  useEffect(() => {
    if(!userReducer.isLoggedIn && !userReducer.sessionChecked){
      const token = getToken()
      if(token) {
        try{ 
          let decoded = jwt.decode(token);
          let userObj = { id: decoded.result.id, name: decoded.result.name, username: decoded.result.username, isLoggedIn: true, wizard: decoded.result.wizard,avatar: decoded.result.avatar}
          dispatch(setSession({user:userObj, token:token}))
          dispatch(getTournaments())
          dispatch(getUserGroups())
        }catch(e){
          dispatch(logout())  
        }
      }else{
        if(userReducer.isLoggedIn){
          dispatch(logout())
        }
      }
      dispatch(checkSession())
    }
  }) 

  const getProtectedRoute = (path, component) => {
    try{
      if (userReducer.isLoggedIn) {
        return <Route exact path={path} component={component} />; 
      }        

    }catch(e){
      console.log("rutas no definidas")
    }
  }

  const getDefault=()=>{
    if(userReducer.sessionChecked){
      if(userReducer.isLoggedIn){
        return <Route exact path="/" component={Home} />;
      }else{
        return <Redirect to="/ingresar" />
      }
    }
  }
  
  let routes = (
    <Switch>
      
      <Layout>
        <ScrollToTop>
        <Switch>
            <Route exact path='/ingresar' component={Login}/>
            {/* <Route exact path='/' component={Home}/> */}
            {getProtectedRoute("/", Home)}
            {getProtectedRoute("/wizard", Wizard)}
            {getProtectedRoute("/pronosticos", Tournaments)}
            {getProtectedRoute("/pronosticos/grupales/:match", ForescastGroup)}
            {getProtectedRoute("/pronosticos/help/:match", ForescastHelp)}
            {getProtectedRoute("/pronosticos/penales/:match", ForescastDeathMatch)}
            {getProtectedRoute("/pronosticos/:id", Forecast)}
            {getProtectedRoute("/grupos", Groups)}
            {getProtectedRoute("/grupos/busqueda", GroupsSearch)}
            {getProtectedRoute("/grupos/crear", GroupsCreate)}
            {getProtectedRoute("/grupos/clasificacion/:id", Ranking)} 
            {getProtectedRoute("/grupos/destacados/:id", OutstandingGroups)} 
            {getProtectedRoute("/ayuda", Help)}
            {getProtectedRoute("/perfil", Profile)}
            {getProtectedRoute("/cambio__clave", Profile)}
            {getDefault()}
        </Switch>
        </ScrollToTop>
      </Layout>
    </Switch>
  );

  return (
    <BrowserRouter>
      {routes}
    </BrowserRouter>
  );

}

export default (App)

