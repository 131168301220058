import React, { useState, useEffect } from 'react'


import api from '../../api'
import TournamentList from '../TournamentList/TournamentList'

import { Button, Row, Col, Container, Input, InputGroup, Alert, Spinner } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { updRequest } from '../../store/actions/user'
import { FaSync } from "react-icons/fa";

import { validationHandleError } from '../../store/actions/utils'


import './GroupsCreate.css';


const GroupCreate = props => {

  const [inputGroup, setInputGroup] = useState('')
  const [tournaments, settournament] = useState([])
  const [tournamentSelected, setTournamentSelected] = useState(0)
  const [loading, setLoading] = useState(false)
  const [hasData, setHasData] = useState(false)
  const [message, setMessage] = useState(false)
  const [urlImg, setUrlImg] = useState("false")
  const [error, setError] = useState(false)
  const dispatch = useDispatch()


  const objReducer = useSelector(state => {
    return {
      idUser: state.user.id
    }
  })

  useEffect(() => {
    if (!hasData) {
      setLoading(true)
      api.tournaments.getTournaments().then(data => {
        if (data.status) {
          settournament(data.result.filter(x => x.status_tournament === 1))

          setHasData(true)
          setLoading(false)
        }
      })
    }
    changeImgHandler();
  }, [hasData])


  const changeHandler = (e) => {
    setInputGroup(e.target.value)
  }

  const createGroupHandler = async () => {
    setMessage(false)
    if (tournamentSelected === 0) {
      setMessage({ color: 'warning', title: 'Tienes que seleccionar un Torneo!' })

    } else {

      let send = {
        name: inputGroup,
        id_owner: objReducer.idUser,
        id_tournament: tournamentSelected,
        avatar: urlImg
      }

      try {
        let sendGroups = await api.groups.create(send)
        if (sendGroups.statusCode !== 201) {
          setError({ hasError: true, name: sendGroups.name, message: 'No se pudo crear el grupo', status: sendGroups.status })
        } else {
          dispatch(updRequest({ requestGroup: true }))
          setMessage({ color: 'success', title: 'El grupo fue creado con éxito!' })
          if (props.onGroupCreated) {
            props.onGroupCreated()
          }
        }

      } catch (e) {
        validationHandleError(e)
        setError({ hasError: true, name: e.name, message: e.message, status: e.status })
      }
    }

  }

  const selectTournament = (id) => {
    setMessage(false)
    // console.log("id ", id)
    setTournamentSelected(id)
  }

  const changeImgHandler = () => {
    const randomNumber = Math.round(Math.random() * 1000)
    // const randomLetter = String.fromCharCode(Math.round(Math.random() * 1000))
    const urlImg = process.env.REACT_APP_URL_AVATAR_GROUP + `${randomNumber}.svg`

    setUrlImg(urlImg)

  }

  let messageDiv = null;

  if (loading) {
    return (<Spinner />)
  }

  if (message) {
    messageDiv = <Alert color={message.color}> {message.title}</Alert>
  }

  if (error) {
    messageDiv = <Alert color="danger"> Tuvimos un Problema {error.message}</Alert>
  }

  return (
    <Container className="groups-container groupcreate-container">
      <Row>
        <Col>
          <div className="groups-avatar" onClick={changeImgHandler}>
            <img className="groups-img-avatar" src={urlImg} alt="avatar" />
            <div className="groups-avatar__randomize">
              <FaSync size="20"/>
            </div>
          </div>
          <InputGroup>
            <Input type="text" name="group" id="group" placeholder="Nombre del grupo" value={inputGroup} onChange={changeHandler} />
          </InputGroup>
          <TournamentList data={tournaments} id="id_tournament" defaultText="" itemName="tournament" title="Torneos Disponibles" clickHandler={selectTournament} />
          <Button onClick={createGroupHandler} className="button create-save">Crear Grupo</Button>
          {messageDiv}
        </Col>
      </Row>
    </Container>
  )

}

GroupCreate.defaultProps = {
  onGroupCreated: false
}

export default GroupCreate