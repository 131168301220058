import {
  GET_STATS, 
  SET_SESSION,
  CHECK_SESSION,
  LOGOUT,
  LOADING,
  ADD_GROUPS, 
  SET_NOTIFICATIONS,
  LEAVE_GROUP,
  UPDATE_REQUEST,
  ERROR 
} from '../types/userTypes'

import Cookies from 'js-cookie';
import api from '../../api';
import { validationHandleError, customError } from './utils'
import { getToken, removeToken  } from '../../utils/utils';

export const setSession =  (_obj) => async (dispatch, getState) => {
  dispatch({
    type: LOADING
  })
  try{    
    const token = getToken()

    if(token){
      removeToken()
    }
    Cookies.set('token',_obj.token, { expires: 2 })
    localStorage.setItem('_t',_obj.token)

    dispatch({
      type: SET_SESSION,
      payload: _obj.user
    })
  }catch(e){
    validationHandleError(e)
  }
  
}
export const checkSession =  () => async (dispatch, getState) => {

  try{    
    dispatch({
      type: CHECK_SESSION
    })
  }catch(e){
    validationHandleError(e)
  }
  
}
export const logout =  () => async (dispatch, getState) => {
  console.log("Logout")
  logoutHandler();
  try{
    dispatch({
      type: LOGOUT
    })

  }catch(e){
    validationHandleError(e)
  }
  
}

export const logoutHandler = (reload = true, redirect = '') =>{
    try {
      removeToken()
    } catch (error) {
      console.log("logoutHandler Error")
    }
    if(reload){
      window.location.reload();
    }else{
      if (redirect){
        window.location.href = redirect;
      }
      else{
        window.location.reload();
      }
    }
}

export const login =  (_obj) => async (dispatch, getState) => {
  try{
    dispatch({
      type: LOADING
    })

    // const authBody = {apiKeyToken: 'eee93db0f78abe81584472a2a9a0e88b39c90a37f943e7cb50eca89484e8fdf5', rememberMe: _obj.remember}
    const authBody = { apiKeyToken: process.env.REACT_APP_APIKEYTOKEN, rememberMe: _obj.remember, idExternal: _obj.idExternal}
    
    const data = await api.auth.loginForm(authBody, _obj.email, _obj.password)
    if(data.status!==200){ 
      customError.prototype = Error.prototype;
      const message = ( data.message || data.result.message )
      // eslint-disable-next-line
      throw {name : (data.error || '' ), message : message , status:data.status};
    }

    dispatch(setSession(data.result))
    dispatch(getUserGroups())

  
  }catch(e){
    let shouldDisptach = validationHandleError(e,false)
    if(shouldDisptach){
      dispatch({
        type: ERROR,  
        payload: {error:{hasError: true, name: e.name, message: e.message, status: e.status}}
      })
    }
  }
  
}


export const getUserGroups =  () => async (dispatch, getState) => {

  try{
    const { user } = getState();
    
    //Si tengo flag de requerir grupo lo hago
    if(user.requestGroup){
      dispatch({
        type: LOADING
      })

      let data = await api.user.getUserGroups(user.id)
      if(data.statusCode!==200 && data.statusCode !== 409){ 
        customError.prototype = Error.prototype;
        const message = ( data.message || data.result.message )
        // eslint-disable-next-line
        throw {name : (data.error || '' ), message : message , status:data.status};
      }
      if(data.statusCode === 409){
        data.result=[]
      }
      let requestGroup= true
      if(data.result.find(group => group.user_status === 'Pending')){
        requestGroup= false
      }
      dispatch({
        type: ADD_GROUPS,
        payload: {groups: data.result, requestGroup}
      })

    }else{
      console.log("NO HAGO NADA PORQUE YA TENGO GRUPOS")
    }
  
  }catch(e){
    
    let shouldDisptach = validationHandleError(e)
    if(shouldDisptach){
      dispatch({
        type: ERROR,  
        payload: {error:{hasError: true, name: e.name, message: e.message, status: e.status}}
      })
    }
  }
  
} 


export const createUserGroups = (group) => async (dispatch, getState) => {

  try{
    const { user } = getState();
    
    //Si tengo flag de requerir grupo lo hago
    if(user.requestGroup){
      dispatch({
        type: LOADING
      })

      let data = await api.user.createUserGroups(user.id, group)
      if(data.statusCode!==200){ 
        customError.prototype = Error.prototype;
        const message = ( data.message || data.result.message )
        // eslint-disable-next-line
        throw {name : (data.error || '' ), message : message , status:data.statusCode};
      }
      let requestGroup= true
      if(data.result.find(group => group.user_status === 'Pending')){
        requestGroup= false
      }
      dispatch({
        type: ADD_GROUPS,
        payload: {groups: data.result, requestGroup}
      })

    }else{
      console.log("NO HAGO NADA PORQUE YA TENGO GRUPOS")
    }
  
  }catch(e){
    
    let shouldDisptach = validationHandleError(e)
    if(shouldDisptach){
      dispatch({
        type: ERROR,  
        payload: {error:{hasError: true, name: e.name, message: e.message, status: e.status}}
      })
    }
  }
  
} 


export const getUserNotifications =  (typeNotification) => async (dispatch, getState) => {

  try{
    const { user } = getState();
    var notifications = user.notifications
    switch (typeNotification) {
      case 'groups':
        let data = await api.user.getUseGroupsNotifications(user.id)
        data.statusCode = 200
        if(data.statusCode!==200){ 
          customError.prototype = Error.prototype;
          const message = ( data.message || data.result.message )
          // eslint-disable-next-line
          throw {name : (data.error || '' ), message : message , status:data.statusCode};
        }
        
        if(Object.keys(data.result[0].groups).length > 0){
          notifications = {...notifications, groups: data.result[0].groups}

          dispatch({
            type: SET_NOTIFICATIONS,
            payload: notifications
          })
  
        }

        break;
    
      default:
        break;
    }
  
  }catch(e){
    
    let shouldDisptach = validationHandleError(e)
    if(shouldDisptach){
      dispatch({
        type: ERROR,  
        payload: {error:{hasError: true, name: e.name, message: e.message, status: e.status}}
      })
    }
  }
  
} 

export const approvalHandler =  (_obj) => async (dispatch, getState) => {

  try{
    const { user } = getState();
    
    let notificationsGroups = user.notifications.groups
    let index = notificationsGroups.pendings.findIndex(notification => notification.id === _obj.idApproval)
    if(index < 0){
      //No encontre la notificacion
      console.log("NO ENCONTLE NOTIFACTION, TOY PLEOCUPALO")
    }

    let data = await api.groups.approvalGroup({id_owner: _obj.id_owner ,id_user: _obj.id_user ,id_state: _obj.approvalStatus}, _obj.id_group)
    if(data.statusCode!==200){ 
      customError.prototype = Error.prototype;
      const message = ( data.message || data.result.message )
      // eslint-disable-next-line
      throw {name : (data.error || '' ), message : message , status:data.statusCode};
    }

    notificationsGroups.pendings.splice(index,1) 

    const notifications = {...user.notification, groups: notificationsGroups}

    dispatch({
      type: SET_NOTIFICATIONS,
      payload: notifications
    })
  
  }catch(e){
    validationHandleError(e)
    
    dispatch({
      type: ERROR,  
      payload: {error:{hasError: true, name: e.name, message: e.message, status: e.status}}
    })
  }
  
} 

export const getUserStats =  (idUser) => async (dispatch, getState) => {
  const { user } = getState();

  if(user.stats.length === 0 ){
    dispatch({
      type: LOADING
    })
    
    try{
      var data = await api.user.stats(idUser);
      if (data.statusCode !== 200 ){
        customError.prototype = Error.prototype;
        const message = ( data.message || data.result.message )
        // eslint-disable-next-line
        throw {name : (data.error || '' ), message : message , status:data.statusCode};
      }else{
        dispatch({
          type: GET_STATS,
          payload: data.result
        })
        console.log("error")
      }
    }catch(e){
      validationHandleError(e)
      
      dispatch({
        type: ERROR,  
        payload: {error:{hasError: true, name: e.name, message: e.message, status: e.status}}
      })
    }
  }
  
}

export const leaveGroup =  (_obj) => async (dispatch, getState) => {

  try{
    const { user } = getState();
    
    let groupList = user.groups
    let index = groupList.findIndex(group => group.id_group === _obj.id_group)
    if(index < 0){
      //No encontre la notificacion
      console.log("NO ENCONTLE GLUPO, TOY PLEOCUPALO")
    }

    let data = await api.groups.leave({id_owner: _obj.id_owner ,id_user: user.id ,id_state: _obj.approvalStatus}, _obj.id_group)
    if(data.statusCode!==200){ 
      customError.prototype = Error.prototype;
      const message = ( data.message || data.result.message )
      // eslint-disable-next-line
      throw {name : (data.error || '' ), message : message , status:data.status};
    }

    groupList.splice(index,1) 
    console.log("grupo removido ", groupList, " index ", index)

    dispatch({
      type: LEAVE_GROUP,
      payload: groupList
    })
  
  }catch(e){
    validationHandleError(e)
    
    dispatch({
      type: ERROR,  
      payload: {error:{hasError: true, name: e.name, message: e.message, status: e.status}}
    })
  }
  
} 

//Funcion que dispacha los flags para decirle a los actions que utilzan api si tienen que ir a buscar a internet o no
export const updRequest =  (_obj) => async (dispatch, getState) => {

  dispatch({
    type: UPDATE_REQUEST,
    payload: _obj
  })

} 

//Funcion para setear errores desde una page
export const setError =  (_obj) => async (dispatch, getState) => {

  dispatch({
    type: ERROR,  
    payload: {error:{hasError: true, name: _obj.name, message: _obj.message, status: _obj.status}}
  })

} 

export const cleanError =  () => async (dispatch, getState) => {

  dispatch({
    type: ERROR,  
    payload: {error:{hasError: false}}
  })

} 

