import React from 'react';
import './DeathMatch.css'
import { Col, Row, Input, Card, CardBody } from 'reactstrap';
import useReactRouter from 'use-react-router';
import ForecastPenalties from './ForecastPenalties';
const slugify = require('slugify')



function DeathMatch(props) {
    var scoreHome = ''
    var scoreAway = ''
    var classStatusMatch = null
    const { history } = useReactRouter();


    const goGroups = () => {


        history.replace('/grupos')

    }

    if (props.match.match_result) {
        let spli_result = props.match.match_result.split('-')

        scoreHome = parseInt(spli_result[0])
        scoreAway = parseInt(spli_result[1])
    }
    if (props.match.status_prediction === 'Unavailable') {
        if (props.match.user_status === 'No Played') {
            scoreHome = '-'
            scoreAway = '-'
        }
    }
    if (props.match.user_status === 'No Played') {
        console.log('props.match.status_prediction', props.match.status_prediction);
    }


    if (props.match.user_status) {
        classStatusMatch = slugify(props.match.user_status, {
            replacement: '_',    // replace spaces with replacement
            remove: null,        // regex to remove characters
            lower: true,         // result in lower case
        })
    }


    return (
        <React.Fragment >
            <div key={props.index} id={`match-${props.match.id_match}`} className={`widget-default widget-forecastgroup ${classStatusMatch}`}>


                <Row>
                    {(props.match.match_result) &&
                        <Col md="12" xs="12" className="widget-forecastgroup__date">
                            <span className="match-real__title">Resultado Parcial</span>
                        </Col>
                    }
                    <Col md="5" xs="4" className="widget-forecastgroup__local">
                        <img src={props.match.home_image} alt={props.match.home} />
                        <h6>{props.match.home_codename}</h6>
                    </Col>

                    <Col md="2" xs="4" className="widget-forecastgroup__guess" data-match-id={props.match.id_match} data-match-text={props.match.match}>
                        <div className="user-input">
                            <Input
                                type="tel"
                                min="0"
                                maxLength="2"
                                name="input-home"
                                placeholder=""
                                onClick={props.handleFocus}
                                onKeyUp={onkeyup}
                                defaultValue={scoreHome}
                                disabled={true}

                            />
                            <Input
                                type="tel"
                                min="0"
                                maxLength="2"
                                name="input-away"
                                placeholder=""
                                onClick={props.handleFocus}
                                onKeyUp={onkeyup}
                                defaultValue={scoreAway}
                                disabled={true}
                            />
                        </div>
                    </Col>
                    <Col md="5" xs="4" className="widget-forecastgroup__away">
                        <img src={props.match.away_image} alt={props.match.away} />
                        <h6>{props.match.away_codename}</h6>
                    </Col>

                </Row>
            </div>
            
            <ForecastPenalties />

        </React.Fragment >
    )


}

export default DeathMatch;