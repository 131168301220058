import React, { useState, useEffect } from 'react';
import { getForecast, updateMatch } from '../store/actions/tournament'
import { useSelector, useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router';

import Match from '../components/Match/Match'
import { UncontrolledAlert, Container, Spinner} from 'reactstrap';
import './styles/Forecast.css';
import DropDownList from '../components/Utilities/DropDownList';
import slugify from 'slugify';


const Forecast = props => {
  const [loading, setloading] = useState(true);
  const [index, setindex] = useState(0);
  const [indexText, setindexText] = useState();
  const [myselector, setmyselector] = useState([]);
  const [idTournament, setidTournament] = useState();
  const [title, setTitle] = useState();
  
  const { location, history, match } = useReactRouter();
  const reducTournament = useSelector(state => {

    return {
      tournament: state.tournament,
      user: state.user
    }
  })

  const dispatch = useDispatch()

  useEffect(() => {

    let executeFunc = async () => {
      try {
        //console.log('location', location);
        let gettournaments = reducTournament.tournament.tours;
        let current_stage
        let myselector = []
        //console.log('gettournaments', gettournaments);
        if (Object.keys(gettournaments).length) {
          var id_Tournament
          var findtour
          try {
            id_Tournament = location.state.idtournament            
            setTitle(location.state.title )           
            findtour = gettournaments[id_Tournament]
            current_stage = findtour.current_instance
            if (location.state.idStage) {
              current_stage = location.state.idStage
            }

          } catch (error) {
            findtour = findIdTournament(match.params.id, gettournaments)  
            if (!findtour){
              history.push(`/pronosticos`)
            }   
            id_Tournament = findtour
            findtour = gettournaments[id_Tournament]
            current_stage = gettournaments[id_Tournament].current_instance
            setTitle(gettournaments[id_Tournament].tournament)           
          }

          setidTournament(id_Tournament)

          let label = findtour.current_point
          for (let index = 1; index <= findtour.instances; index++) {
            myselector.push({
              index,
              label: `${findtour.current_point} ${index}`
            })
          }


          setloading(true);

          setindex(current_stage)
          setindexText(`${label} ${current_stage}`)

          setmyselector(myselector)
          //console.log('id_Tournamentid_Tournamentid_Tournamentid_Tournamentid_Tournament', id_Tournament);
          dispatch(getForecast({ idTournament: id_Tournament, stageselected: `instance=${current_stage}`, idUser: `user_info=1` }))

          setloading(false);
        }
      } catch (error) {
        setloading(false);

        //console.log(error);
      }
    }
    
    executeFunc();
    // eslint-disable-next-line
  }, [reducTournament.tournament.tours, reducTournament.tournament.stage])

  const findIdTournament = (textMatch,listTournaments) => {
    // eslint-disable-next-line
    return Object.keys(listTournaments).find((x) => {
      
      let slug = slugify(listTournaments[x].tournament, {
        replacement: '_',    // replace spaces with replacement
        remove: null,        // regex to remove characters
        lower: true,         // result in lower case
      })
      if (slug === textMatch){
        return listTournaments[x]
      }
    })
  }

  function changeInstance(e) {

    let current = e.target.getAttribute('datavalue')
    setloading(true);

    dispatch(getForecast({ idTournament: idTournament, stageselected: `instance=${current}`, idUser: `user_info=1` }))
    // setdataLoad(produc.result);

    setloading(false);
    let label = indexText.replace(index, current)
    setindex(current)
    setindexText(`${label}`)

    history.replace(`${location.pathname}`, { idtournament: idTournament, idStage: current, title: title})

  }

  const inputChange = (e, index1) => {

    const inputIdArr = e.target.id.split('_')

    let uResult = (reducTournament.tournament.stages[`stage${idTournament}_${index}`].matches[index1].user_result || '-')
    
      let uResultArr = uResult.split('-')

      if (inputIdArr[0] === 'home') {
        uResultArr[0] = e.target.value.trim()
      }

      if (inputIdArr[0] === 'away') {
        uResultArr[1] = e.target.value.trim()
      }
    
    sendMatchForecast(inputIdArr[1], uResultArr[0], uResultArr[1])
   
  }


  function sendMatchForecast(matchid, matchlocal, matchaway) {
    // //console.log('reducForestsendMatchForecast', reducForest);

    let send = {
      "id_user": reducTournament.user.id,
      "home": (matchlocal.trim() || '' ),
      "away": (matchaway.trim() || '')
    }
    try {
      //console.log("LAALLALAL",send)
      //console.log('matchidmatchid', matchid);
      //console.log('matchlocal', matchlocal);
      //console.log('matchaway', matchaway);
      // matchnode.parentNode.parentNode.classList.add('match-filled');

      // let sendForecast = await api.forecast.sendMatch(matchid, send);
      dispatch(updateMatch({ matchid, send, idTournament, index}))
      
    } catch (error) {
      //console.log('Error', error);
    }
  }



  function handleFocus(e) {
    var el = e.target
    el.select()
    // el.focus();
    // el.scrollIntoView();
  }

  if (loading) return <Spinner style={{ width: '3rem', height: '3rem' }} />
  
  if (reducTournament.tournament.loading || !reducTournament.tournament.stages) {
    return <Spinner style={{ width: '3rem', height: '3rem' }} />
  } else {
    
    var alert = null;
    if (reducTournament.tournament.error) {
      
      let showAlert = { message: reducTournament.tournament.error.message, alertColor: "danger" }
      let classDelay = ''
      alert = (
        <UncontrolledAlert color={showAlert.alertColor} fade={false} className={classDelay}>
            {showAlert.message}
        </UncontrolledAlert>
        )
      }
      // if()
      // setmyselector(sel);
      
      myselector.map((ele, index) => {
        return (<option key={ele.index} value={ele.index}>{ele.label}</option>)
    })
    //console.log("lskjdkjfsdk ", reducTournament.tournament.stages[`stage${idTournament}_${index}`].matches)
    return (
      (idTournament)?
      <React.Fragment >
        <Container className="forecast-matches cf text-center">
          <DropDownList className="form-control instance-selector" current={indexText} list={myselector} onClick={changeInstance} />

        </Container>

          <Match
            list={reducTournament.tournament.stages[`stage${idTournament}_${index}`].matches}
            handleFocus={handleFocus}
            inputChange={inputChange}
            currentStage={index === reducTournament.tournament.tours[idTournament].current_instance}
            />
          

      </React.Fragment >
      : 
      ''
    )
  }



}

export default Forecast;