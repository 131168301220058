import React from 'react';

import './TeamsHistory.css'
import { Button } from 'reactstrap';
import { FaLock, FaUnlock, FaStar } from "react-icons/fa";


function TeamsHistory(props) {
    if (props.premium === false) {
        return (
            <div key={props.index} id={`match-${props.match.id_match}`} className="widget-default widget-teamshistory">
                <div className="widget-teamshistory__container">
                    <div className="widget-streak__special-title">
                        <h3><img src={props.match.home_image} alt={props.match.home} /> {props.match.home} histórico</h3>
                        <div className="widget-teamhistory__indicator"><FaUnlock /></div>
                    </div>
                    <div className="widget-teamshistory__data">

                        <ul>
                            <li><FaStar size="18" /><span>Cada vez que empieza ganando, solo ha perdido el 20% de los partidos.</span></li>
                            <li><FaStar size="18" /><span>Su goleador Edgard Messi lleva 6 partidos anotando</span></li>
                            <li><FaStar size="18" /><span>La mayoria de sus goles lo hacen en el primer tiempo</span></li>
                        </ul>

                    </div>
                </div>
            </div>
        )
    } else {
        return (

            <div key={props.index} id={`match-${props.match.id_match}`} className="widget-default widget-teamshistory premium-demo">
                <div className="widget-teamshistory__container">
                    <div className="widget-premium__notice">
                        <p><span aria-label="img" role="img">😱</span> Oh oh, parece que esta ayuda<br /> está bloqueada!</p>
                        <Button>Desbloquear</Button>
                    </div>
                    <div className="widget-streak__special-title">
                        <h3><img src={props.match.away_image} alt={props.match.away} /> {props.match.away} histórico</h3>
                        <div className="widget-teamhistory__indicator"><FaLock /></div>
                    </div>
                    <div className="widget-teamshistory__data">

                        <ul>
                            <li><FaStar size="18" /><span>Cada vez que empieza ganando, solo ha perdido el 20% de los partidos.</span></li>
                            <li><FaStar size="18" /><span>Su goleador Edgard Messi lleva 6 partidos anotando</span></li>
                            <li><FaStar size="18" /><span>La mayoria de sus goles lo hacen en el primer tiempo</span></li>
                        </ul>

                    </div>
                </div>
            </div>
        )
    }

}

export default TeamsHistory;