import React, { useEffect, useState } from 'react'
import { Spinner, Alert,Container, Row, Col } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router';

import ProfileForm from '../components/ProfileForm/ProfileForm'
import ChangePassword from '../components/ProfileForm/ChangePassword'

import { logout, setSession } from '../store/actions/user'
import api from '../api'
import './styles/Profile.css';


const Profile = props => {
  const [userInfo, setUserInfo] = useState(false)
  const [fieldModified, setFieldModified] = useState({})
  const [changePassword, setChangePassword] = useState(false)
  const [password, setPassword] = useState({ newPassword: '', confirmPassword: '' })
  const [showAlert, setShowAlert] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const { history } = useReactRouter();
  const dispatch = useDispatch()
  const objReducer = useSelector(state => {
    return {
      id: state.user.id
    }
  })

  useEffect(() => {    
    api.user.get(objReducer.id).then(data => {
      setUserInfo(data.result)
    })
  }, [objReducer.id])

  useEffect(() => {
    if(props.location.pathname === '/cambio__clave' && !changePassword){
      setChangePassword(true)
    }

    if(props.location.pathname === '/perfil' && changePassword){
      setChangePassword(false);
    }
  }, [props.location.pathname,changePassword])

  const changeHandler = (e) => {
    let value = e.target.value
    if(e.target.name === 'username'){
      const validator = /[|&;$%@"<>()+ñÑáéíóúÁÉÍÓÚ`´'àèìòùÀÈÌÒÙ]+/g
      if(validator.test(e.target.value)){
        value = value.substring(0,value.length-1)
      }
    }
    
    let newUser = { ...userInfo }
    newUser[e.target.name] = value
    setUserInfo(newUser)

    let newfieldModified = { ...fieldModified }
    newfieldModified[e.target.name] = true
    setFieldModified(newfieldModified)
    setBtnDisabled(false)

  }
 

  const changePasswordHandler = (e) => {
    let newPassword = { ...password }
    newPassword[e.target.name] = e.target.value
    newPassword.error = false


    if (newPassword.newPassword !== newPassword.confirmPassword) {
      newPassword.error = 'Las contraseñas no coinciden'
    }

    setPassword(newPassword)

  }

  const logoutHandler = () => {
    dispatch(logout())
  }

  const updateProfileHandler = async () => {
  
    for (let key in fieldModified) {
      let key2 = key
      if(key === 'name'){
        key2 = 'nombre'
      }
      let data = await api.user.update(objReducer.id, { field_update: key2, value_update: userInfo[key] })
      try {
        if (data.status !== 200) {
          setShowAlert({ color: "danger", message: "Tuvimos un problema para actualizar el perfil" })
        } else {
          setShowAlert({ color: "success", message: "Perfil actualizado correctamente" })
          dispatch(setSession(data.result))
        }
      }
      catch (e) {
        setShowAlert({ color: "danger", message: "Tuvimos un problema para actualizar el perfil" })
      }
    }
  }

  const updateProfilePasswordHandler = async () => {

    if (!password.error && password.newPassword.length > 0) {
      let data = await api.user.update(objReducer.id, { field_update: 'password', value_update: password.newPassword })
      try {
        if (data.status !== 200) {
          setShowAlert({ color: "danger", message: "Tuvimos un problema para actualizar la contraseña" })
        } else {
          setShowAlert({ color: "success", message: "Perfil actualizado correctamente" })
        }
      }
      catch (e) {
        setShowAlert({ color: "danger", message: "Tuvimos un problema para actualizar la contraseña" })
      }
    } else {
      setShowAlert({ color: "danger", message: "Validar Formulario" })
    }
  }

  const changeImgHandler = () =>{
    const randomNumber = Math.round(Math.random() * 1000)
    // const randomLetter = String.fromCharCode(Math.round(Math.random() * 1000))
    const urlImg = process.env.REACT_APP_URL_AVATAR_USER+`${randomNumber}.svg`

    let userNew = {...userInfo}
    userNew.avatar = urlImg

    let newfieldModified = { ...fieldModified }
    newfieldModified.avatar = true
    setFieldModified(newfieldModified)

    setUserInfo(userNew)
    setBtnDisabled(false)
  }


  if (!userInfo) {
    return (<Spinner />)
  }

  let alert = null;
  if (showAlert) {
    alert = (
      <Alert color={showAlert.color}>
        {showAlert.message}
      </Alert>
    )
  }
  let body = <ProfileForm
    user={userInfo}
    changeHandler={changeHandler}
    onPressLogout={logoutHandler}
    onPressUpdate={updateProfileHandler}
    onPressImg={changeImgHandler}
    btnDisabled={btnDisabled}
    onPressChangePw={() => {history.push(`/cambio__clave`)}}
  />

  if (changePassword) {
    body = (<ChangePassword
      changeHandler={changePasswordHandler}
      password={password}
      onPressUpdate={updateProfilePasswordHandler}
      onPressCancel={() => {history.push(`/perfil`)}}
    />
    )
  }

  return (
    <Container className="profile-container">
      <Row className="justify-content-lg-center">
        <Col lg="8">
        {body}
        {alert}
        </Col>
      </Row>
    </Container>
  )

}

export default Profile