import { logoutHandler } from "./user"

export const validationHandleError = async (e, reload = true) =>{
  let shouldDispatch = true
  console.log("WWWEEEE ", e)
  if(e.status === 401 && reload){
    console.log("Realizo Logout por Token Invalido")
    logoutHandler(false, '/ingresar?error=Tu sesión ha finalizado, por favor ingresa de nuevo.')
    shouldDispatch = false
  }
  return shouldDispatch
}

export const customError = (_obj) => {
  this.name = (_obj.name || 'customError');
  this.message = (_obj.message || "");
  this.status = (_obj.status || "");
}