import {
  GET_FORECASTHELP, 
  LOADING, 
  ERROR 
} from '../types/forecasthelpTypes'

const INITIAL_STATE = {
  matches: {},
  loading: false,
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return { 
        ...state, 
        loading: true 
      }
    case ERROR:
      return { 
        ...state, 
        error: action.payload,
        loading: false
      }
    case GET_FORECASTHELP: 
      return {
        ...state,
        matches:{
          ...state.matches, 
          [`match${action.payload.id_match}`] : action.payload
        }, 
        loading:false
      }
    default: return state;
  }
}