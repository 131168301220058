import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner} from 'reactstrap';
import classnames from 'classnames';
import PodiumList from './PodiumList';
import DropDownList from '../Utilities/DropDownList';

function Ranking(props) {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    var max_stages = props.totalstage
    var myselector = []
    for (let index = 1; index < max_stages; index++) {
        myselector.push({
            index,
            label: `${props.currentpoint} ${index}`
        })
    }
    return (
        <React.Fragment >
            <Nav tabs>
                <NavItem className="col-md-4 col-4">
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        Global
                    </NavLink>
                </NavItem>
                <NavItem className="col-md-4 col-4">
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >
                        Jornada
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <div className={`ranking-content__global ${(props.ranking.TourEnded)? 'finished': ''}`}>
                        <PodiumList listpodium={props.ranking.ranking} />
                    </div>
                </TabPane>
                <TabPane tabId="2">
                    <div className="ranking-content__stage">
                        
                        <DropDownList className="form-control instance-selector" current={`${props.currentpoint} ${props.currentstage}`} list={myselector} onClick={props.changeStageRanking} />
                        {(props.loadingJornada) ?                        
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                            :
                            <PodiumList className="ranking-stages__podium" listpodium={props.jornada} />
                        }
                    </div>
                </TabPane>
            </TabContent>

        </React.Fragment >
    );

}

export default Ranking;