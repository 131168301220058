import React from 'react'
import useReactRouter from 'use-react-router';


import GroupCreateComponente from '../components/GroupCreate/GroupCreate'


const GroupCreate = props => {
  const { history } = useReactRouter();
  const onGroupCreated = () =>{
    setTimeout(function () {
      history.replace('/grupos')
    }, 2000)
  }

  return (
    <GroupCreateComponente onGroupCreated={onGroupCreated}/>
  )

}

export default GroupCreate