import { getToken } from './utils/utils';
const BASE_URL = (process.env.REACT_APP_BACK_URL || 'https://prode.api.playtown.com.ar/prode');


async function callApi(endpoint, options = {}) {
    const token = getToken()
    options.headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...options.headers
    };

    if (token && !options.headers.Authorization) {
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${token}`
        }
    }

    
    var url = BASE_URL + endpoint;
    if (options.mockup){
        url = 'http://10.1.10.104:1234' + endpoint;
        delete options.mockup;
    }
    const response = await fetch(url, options);
    let data;
    if(response.status === 401){
        data = {statusCode:401, result:{message:'Invalid Token'}}
    }else{
        data = await response.json();
    }
    
    return {
        status: response.status,
        statusText: response.statusText,
        ...data
    };
}

const api = {

    auth:{
        loginForm(authBody, email, password) {
            return callApi(`/auth/sign-in`,{
                method: 'POST',
                body: JSON.stringify(authBody),
                headers: {
                    Authorization: `Basic ${btoa(email+":"+password)}`
                }
              });
        },
        signUp(userObj){
            return callApi(`/auth/sign-up`,{
                method: 'POST',
                body: JSON.stringify(userObj),
              }
            );
        }
    },
    user:{
        get(idUser) {
            return callApi(`/users/${idUser}`);
        },
        getUserGroups(idUser) {
            return callApi(`/users/${idUser}/groups`);
        },
        getUseGroupsNotifications(idUser) {
            return callApi(`/users/${idUser}/notifications/`);
        },
        update(idUser,userObj){
            return callApi(`/users/${idUser}`,{
                method: 'PATCH',
                body: JSON.stringify(userObj),
              }
            );
        },
        stats(idUser){
            return callApi(`/users/${idUser}/stats`);
        }
    },
    tournaments:{
        getTournaments() {
            return callApi(`/tournaments/`);
        }
    },
    groups:{
        get(idGroup){
            return callApi(`/groups/${idGroup}`);
        },
        create(groupObj) {
            return callApi(`/groups/`,{
                method: 'POST',
                body: JSON.stringify(groupObj),
              }
            );
        },
        join(groupObj,idGroup) {
            return callApi(`/groups/${idGroup}/join`,{
                method: 'POST',
                body: JSON.stringify(groupObj),
              }
            );
        },
        approvalGroup(groupObj, idGroup) {
            return callApi(`/groups/${idGroup}/approval`,{
                method: 'PATCH',
                body: JSON.stringify(groupObj), 
              }
            );
        },
        find(group) {
            return callApi(`/groups/find?name=${group}`);
        },
        leave(groupObj, idGroup) {
            return callApi(`/groups/${idGroup}/leave`,{
                method: 'PATCH',
                body: JSON.stringify(groupObj), 
              });
        },
    },
    forecast: {
        list(tournament) {
            return callApi(`/tournaments/${tournament}/fixture`);
        },
        read(userId) {
            return callApi(`/users/${userId}`);
        },
        byDate(idTournament, params){
            if(params){
                let str_params = params.join('&')
                return callApi(`/tournaments/${idTournament}/fixture?${str_params}`);
            }else{                
                return callApi(`/tournaments/${idTournament}/fixture`);
            }
        },
        sendMatch(idMatch, forecast) {
            console.log('forecast api', JSON.stringify(forecast));
            return callApi(`/matches/${idMatch}/prediction`, {
                method: 'PATCH',
                body: JSON.stringify(forecast),
            });
        },
    },
    forecastgroup: {
        get(idMatch) {
            return callApi(`/matches/${idMatch}/ranking`);
        },
    },
    forecasthelp: {
        get(idMatch) {
            return callApi(`/matches/${idMatch}/stats/`);
        },
    },
    ranking: {
        get(idGroup) {
            return callApi(`/groups/${idGroup}/ranking/global`);
        },
        getbyStage(idGroup,stage) {
            return callApi(`/groups/${idGroup}/ranking/jornada?instance=${stage}`);
        },
    },
    ui: {
        menu() {
            return callApi(`/utils/home`);
        },
    }


};

export default api;