import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import SingleMatch from './SingleMatch';


const Match = (props) => {

  return (

    <Container className="match-component">
      <Row>
        <Col>
          {props.list.map((match, index) => {
            return <SingleMatch key={index} match={match} index={index} {...props} listgroup={[]} last={props.list.length - 1} />
          })}
        </Col>
      </Row>
    </Container>
  )
}

export default Match;