import { combineReducers } from 'redux'
import forecasthelp from '../reducers/forecasthelpReducer'
import forecastgroup from '../reducers/forecastgroupReducer'
import tournament from '../reducers/tournamentReducer'
import ui from '../reducers/uiReducer'
import user from '../reducers/userReducer'
import ranking from '../reducers/rankingReducer'

export default combineReducers({
  forecasthelp,
  forecastgroup,
  tournament,
  ui,
  ranking,
  user
});