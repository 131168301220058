import {
  UPDATE_MATCH, 
  GET_TOURNAMENT, 
  ADD_MATCH, 
  LOADING, 
  SINGLE_MATCH_UPDATE,
  ERROR, 
  ERROR_UPDATE 
} from '../types/tournamentTypes'

import { validationHandleError, customError } from './utils'
import api from '../../api';

export const getForecast = ({ idTournament, stageselected, idUser}) => async (dispatch, getState) => {
  const { tournament } = getState();

  dispatch({
    type: LOADING
  })
  
  try{
    var response;
    let find = tournament.stages[`stage${idTournament}_${stageselected.replace('instance=', '')}`]
    // Buscando siempre en API la fecha actual (FORECAST)
    if (tournament.tours[idTournament].current_instance === stageselected.replace('instance=', '')){
      find = false
    }
    if (find){
      response = find;
    }else{
      var data = await api.forecast.byDate(idTournament, [idUser, stageselected]);
      if (data.statusCode !== 200) {
        customError.prototype = Error.prototype;
        const message = (data.message || data.result.message)
        // eslint-disable-next-line
        throw { name: (data.error || ''), message: message, status: data.status };
      }
      response = data.result
    }
    dispatch({
      type: ADD_MATCH,
      payload: { ...response, id_tournament: idTournament}
    })
  
  }catch(e){
    let shouldDisptach = validationHandleError(e)
    if (shouldDisptach) {
      dispatch({
        type: ERROR
      })
    }
  }
  
} 

export const getTournaments = () => async (dispatch, getState) => {
  
  dispatch({
    type: LOADING
  })

  try{
    const { tournament } = getState();
    var response
    if (!tournament.tours.length){
      let data = await api.tournaments.getTournaments()

      if (data.statusCode !== 200) {
        customError.prototype = Error.prototype;
        const message = (data.message || data.result.message)
        // eslint-disable-next-line
        throw { name: (data.error || ''), message: message, status: data.status };
      }
      let custArray = {}
      // eslint-disable-next-line
      data.result.map((one)=>{
        custArray[one.id_tournament] = one
      })
      response = custArray
    }else{
      response = tournament.tours
    }
    dispatch({
      type: GET_TOURNAMENT,
      payload: response
    })
  
  }catch(e){
    let shouldDisptach = validationHandleError(e)
    if (shouldDisptach) {
      dispatch({
        type: ERROR,
        payload: e
      })
    }
  }
} 
  
export const updateMatch = ({ matchid, send, idTournament, index }) => async (dispatch, getState) => {
  
  // dispatch({
  //   type: LOADING
  // })

  dispatch({
    type: SINGLE_MATCH_UPDATE,
    payload: { idMatch: matchid, id_tournament: idTournament, current_instance: index}
  })

  try{
    if (parseInt(send.home) >= 0 && parseInt(send.away) >= 0){
      let rectify = {
        "id_user": send.id_user,
        "home": parseInt(send.home),
        "away": parseInt(send.away)
      }
      console.log('rectify redux', rectify);

      let data = await api.forecast.sendMatch(matchid, rectify)

      if (!(data.statusCode == 201)) {
        
        customError.prototype = Error.prototype;
        const message = (data.message || data.result.message)
        // eslint-disable-next-line
        throw { name: (data.error || ''), message: message, status: data.status };
      }
    }

    dispatch({
      type: UPDATE_MATCH,
      payload: { idMatch: matchid, forecast: send, id_tournament: idTournament, current_instance: index}
    })
  
  }catch(e){
    let shouldDisptach = validationHandleError(e)
    
    if (shouldDisptach) {
      dispatch({
        type: ERROR_UPDATE,
        payload: { idMatch: matchid, forecast: send, id_tournament: idTournament, current_instance: index , error: e}
      })
    }
  }
  
} 
