import {
  UPDATE_MENU, 
  LOADING, 
  ERROR 
} from '../types/uiTypes'


import api from '../../api';
import CryptoJS from 'crypto-js';
import { validationHandleError, customError } from './utils'


export const getHomeMenu =  () => async (dispatch, getState) => {
  const { ui } = getState();

  if(ui.menu.length === 0){
    dispatch({
      type: LOADING
    })

    try{
      var data = await api.ui.menu();
      if(data.statusCode!==200){ 
        customError.prototype = Error.prototype;
        const message = ( data.message || data.result.message )
        // eslint-disable-next-line
        throw {name : (data.error || '' ), message : message , status:data.status};
      }
      
      if (data.status){
        const ui = CryptoJS.AES.encrypt(JSON.stringify(data.result), process.env.REACT_APP_SEED);
        localStorage.setItem('ui', ui); 
        dispatch({
          type: UPDATE_MENU,
          payload: data.result
        })
      }else{
        customError.prototype = Error.prototype;
        const message = ( data.message || data.result.message )
        // eslint-disable-next-line
        throw {name : (data.error || '' ), message : message , status:data.status};
      }
    }catch(e){
      try{
        let ui = localStorage.getItem('ui');
        var bytesProfile = CryptoJS.AES.decrypt(ui, process.env.REACT_APP_SEED);
        var decryptedProfile = bytesProfile.toString(CryptoJS.enc.Utf8);
        const uiBackup = JSON.parse(decryptedProfile);
        dispatch({
          type: UPDATE_MENU,
          payload: uiBackup
        })
      }catch(e){
        
        localStorage.removeItem('ui')
      }
      
      validationHandleError(e)
      dispatch({
        type: ERROR,  
        payload: {error:{hasError: true, name: e.name, message: e.message, status: e.status}}
      })
    }
  }
  
} 