import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { UncontrolledAlert, Spinner } from 'reactstrap';

import { login, cleanError } from '../store/actions/user'
import api from '../api'
import { checkValidity, _GET, EventListenerIframe  } from '../utils/utils'

import './styles/Login.css';
import LoginForm from '../components/Login/Login';

const form = {
  formElements: {
    name: {
      value: '',
      validation: {},
      valid: true,
      touched: false
    },
    username: {
      value: '',
      validation: {},
      valid: false,
      touched: false
    },
    email: {
      value: '',
      validation: {
        isEmail: true
      },
      errorMessage: 'E-mail inválido',
      required: true,
      valid: false,
      touched: false
    },
    password: {
      value: '',
      validation: {
        minLength: 0
      },
      errorMessage: 'La contraseña debe tener un minimo 6 caracteres',
      valid: false,
      touched: false

    },
    confirmPassword: {
      value: '',
      validation: {
        isConfirmPassword: true
      },
      errorMessage: 'Las contraseñas no coinciden',
      valid: false,
      touched: false
    }
  },
  remember: true,
  formLoginValid: false,
  formSignUpValid: false
}

const Login = props => {
  const [inputForm, setInputForm] = useState(form);
  const [forgotPass, setForgotPass] = useState(false);
  const [createAccount, setCreateAccount] = useState(false)
  const [error, setError] = useState();
  const [activeTab, setActiveTab] = useState('1');
  const [showAlert, setShowAlert] = useState(false);
  const [classDelay, setclassDelay] = useState('');
  const [loadingSign, setloadingSign] = useState(false);
  const [notAccount, setNotAccount]= useState(false);
  const dispatch = useDispatch();

  const toggle = tab => {
    setError()
    if (activeTab !== tab) setActiveTab(tab);
  }

  const userReducer = useSelector(state => {
    return { isLoggedIn: state.user.isLoggedIn, userError: state.user.error, userLoading:state.user.loading }
  })

  useEffect(() => {
    if (_GET('error')) {
      setShowAlert({ message: _GET('error'), alertColor: "danger" })
      window.history.pushState("", document.title, window.location.pathname);

      setTimeout(function () {
        setclassDelay('alert-hidden')
      }, 5000)
    }

    //here you pass whatever you want to appear in the url after the domain /
    
  }, [])

  useEffect(() => {
    if (userReducer.userError.hasError && !error) {
      let message = ''
      if (userReducer.userError.status === 401) {
        message = "Contraseña inválida 🙅‍♂️🙅"
      } else {
        if (userReducer.userError.status === 409) {
          console.log(userReducer.userError)
          if(userReducer.userError.message === 'No existe el email'){
            if (!createAccount) {
              setCreateAccount(true)
            }
            setNotAccount(true)
            // message = "Todavía no tenés cuenta 😱"
          }
        }else {
          message = "Tuvimos un problema al autenticar la cuenta"
        }
      }
      let error = { message: message, status: userReducer.userError.status }
      setError(error)
    }

  }, [userReducer,createAccount, error])


  const handleChange = e => {
    if (error) {
      setError(true)
      setCreateAccount(false)
    }
    let formTmp = { ...inputForm }
    formTmp.formElements[e.target.name].value = e.target.value;
    formTmp.formElements[e.target.name].valid = checkValidity(e.target.value, inputForm.formElements[e.target.name].validation);
    formTmp.formElements[e.target.name].touched = true;

    formTmp.formElements.confirmPassword.valid = (formTmp.formElements.confirmPassword.value === formTmp.formElements.password.value)


    let formIsValid = true;
    if (activeTab === "1") {
      formIsValid = inputForm.formElements.email.valid && formIsValid
      formIsValid = inputForm.formElements.password.valid && formIsValid

      formTmp.formLoginValid = formIsValid;
    } else {
      for (let inputIdentifier in inputForm.formElements) {
        formIsValid = inputForm.formElements[inputIdentifier].valid && formIsValid
      }
      formTmp.formSignUpValid = formIsValid;
    }

    setInputForm(formTmp)
  }

  // const handleHideAlert = () => {
  //   console.log(handleHideAlert)
  // }

  const handleSubmit = async(e) => {
    e.preventDefault();
    setError()
    if(createAccount){
      setCreateAccount(false)
    }else{
      try {
        
        if (inputForm.formLoginValid) {
          setloadingSign(true)
    
          let timenow = Date.now()
    
          let firebaseId = ''
          if (process.env.REACT_APP_SHOW_NOT_IFRAME === 'true') {
            firebaseId = await EventListenerIframe(timenow)
          }
    
          setloadingSign(false)
          dispatch(login({ email: inputForm.formElements.email.value, password: inputForm.formElements.password.value, remember: inputForm.remember, idExternal: firebaseId}))
        } else {
          setError({ message: 'Complete el Formulario de forma correcta' })
        }
      } catch (e) {
        setShowAlert({ message: 'No tiene permisos para usar este sistema', alertColor: "danger" })
        setloadingSign(false)
      }
    }
  }

  const signupHandler = async (e) => {
    e.preventDefault();
    dispatch(cleanError())
    if(createAccount || inputForm.formLoginValid){
      if (inputForm.formLoginValid) {
        try {
          const randomNumber = Math.round(Math.random() * 1000)
          // const randomLetter = String.fromCharCode(Math.round(Math.random() * 1000))
          const urlAvatar = process.env.REACT_APP_URL_AVATAR_USER + `${randomNumber}.svg`
          setloadingSign(true)

          let timenow = Date.now()
          let firebaseId = ''
          if (process.env.REACT_APP_SHOW_NOT_IFRAME === 'true') {
              firebaseId = await EventListenerIframe(timenow)
          }
          
          const formInfo = {
            email: inputForm.formElements.email.value.trim(),
            password: inputForm.formElements.password.value.trim(),
            username: inputForm.formElements.email.value.split('@')[0],
            name: inputForm.formElements.email.value.split('@')[0],
            avatar: urlAvatar,
            idExternal: firebaseId
          }
          let data = await api.auth.signUp(formInfo)

          setloadingSign(false)
          if (data.statusCode === 201 || data.statusCode === 200) {
            toggle('1')
            setShowAlert({ message: "Felicitaciones! Ya creaste el usuario!", alertColor: "success" })
            let newInputForm = { ...inputForm }
            newInputForm.formLoginValid = true;
            setInputForm(newInputForm)
            setTimeout(function () {
              dispatch(login({ email: inputForm.formElements.email.value, password: inputForm.formElements.password.value, remember: inputForm.remember, idExternal: firebaseId }))
            }, 2000)
          } else {

            setError({ message: "No pudimos crearte la cuenta, intentalo mas tarde" })

          }
        } catch (e) {

          setError({ message: "Ocurrio un Error, no pudimos crearte la cuenta" })
        }
      } else {
        setError({ message: 'Complete el Formulario de forma correcta' })
      }
    }else{
      setCreateAccount(true)
    }
  }


  const forgotPassFlagHandler = () => {
    setForgotPass(state => !state)
  }

  const recoverHandleSubmit = () => {
    console.log("recoverHandler")
  }

  const rememberHandler = (e) => {
    let newInputForm = { ...inputForm };
    newInputForm.remember = !newInputForm.remember

    setInputForm(newInputForm)

  }

  // let onConfirmRedirect = null;

  let componentToShow = (
    <LoginForm
      type='login'
      onChange={handleChange}
      onSubmit={handleSubmit}
      formValues={inputForm.formElements}
      error={error}
      forgotPassHandler={forgotPassFlagHandler}
      rememberHandler={rememberHandler}
      remember={inputForm.remember}
      signupHandler={signupHandler}
      createFlag={createAccount}
    />)
  if (forgotPass) {
    // onConfirmRedirect = forgotPassFlagHandler

    componentToShow = (
      <LoginForm
        type='forgot'
        onChange={handleChange}
        onSubmit={recoverHandleSubmit}
        formValues={inputForm.formElements}
        error={error}
        forgotPassHandler={forgotPassFlagHandler}
      />
    )
  }

  let alert = null;
  if (showAlert) {
    alert = (
      <UncontrolledAlert color={showAlert.alertColor} fade={false} className={classDelay}>
        {showAlert.message}
      </UncontrolledAlert>
    )
  }


  if(notAccount){
    componentToShow =  (
      <div className="login-form">
      <form onSubmit={props.onSubmit}>

        <div className="login-form__account">
          <p>UPS</p>
          <div>No pudiste ingresar con la cuenta: </div>
          <div style={{fontWeight:'bold'}}>{inputForm.formElements.email.value}</div>
        </div>
        <div className="login-form__account-login">
          <p>¿Queres Crear una Cuenta?</p>
          <button type="submit" className={`button-login button-disabled`} onClick={()=>{setCreateAccount(false);setNotAccount(false)}} >Cambiar Usuario</button>
          <button type="submit" className={`button-signup button-active`} onClick={()=>{setCreateAccount(true);setNotAccount(false)}}>Crear nueva cuenta</button>
        </div>
      </form>
    </div>
    )
  }

  if (userReducer.userLoading || loadingSign) {
    return <Spinner style={{ width: '3rem', height: '3rem' }} />
  }
  
  return (
    <div className="form-container" style={{ backgroundImage: `url("header-background.png")` }}>
      {
        (userReducer.isLoggedIn)
          ? <Redirect to={'/'} />
          : ''
      }
      <div className="header-brand">
        <img src="brand_192x192.png" alt="logo" />
      </div>

      {componentToShow}
      {alert}
    </div>
  )
}

export default Login;