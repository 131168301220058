import React from 'react'
import { Container,Row,Col, Button} from 'reactstrap'
import useReactRouter from 'use-react-router';
import './styles/Help.css';
import { FaDev } from "react-icons/fa";

const Help = props => {
  const { history } = useReactRouter();

    const redirectWizard = () => {

      history.push('/wizard')

    }

  return (
  <Container className="faq"> 
    <Row>
      <Col>
    <h5>Reglamento</h5>
    <p>
    Este es el reglamento que deberás leer y a través del cual se regirá el portal PROODE que ponemos a tu disposición. Para el mismo, se tomarán los datos provenientes de los resultados de los partidos de los torneos de Primera División y Nacional B que se encuentren en curso, en el futbol Argentino.
    </p>
    <Button to color="primary" size="lg" block onClick={redirectWizard} style={{width:'50%',height:'70px',background:'none',border:'1px solid #fff',margin:'1rem auto'}}><FaDev size={30} style={{marginRight:'.5rem'}}/>Ver Wizard</Button>            
    </Col>
    </Row>
  </Container>
  )

}

export default Help