import React from 'react';

import './SingleForecastGroup.css'
import { Col, Row, Input, Card, CardBody } from 'reactstrap';
import useReactRouter from 'use-react-router';
const slugify = require('slugify')


function SingleForecastGroup(props) {
    var scoreHome = ''
    var scoreAway = ''
    var classStatusMatch = null
    const { history } = useReactRouter();


    const goGroups = () => {


        history.replace('/grupos')

    }

    if (props.match.match_result) {
        let spli_result = props.match.match_result.split('-')

        scoreHome = parseInt(spli_result[0])
        scoreAway = parseInt(spli_result[1])
    }
    if (props.match.status_prediction === 'Unavailable') {
        if (props.match.user_status === 'No Played') {
            scoreHome = '-'
            scoreAway = '-'
        }
    }
    if (props.match.user_status === 'No Played') {
        console.log('props.match.status_prediction', props.match.status_prediction);
    }


    if (props.match.user_status) {
        classStatusMatch = slugify(props.match.user_status, {
            replacement: '_',    // replace spaces with replacement
            remove: null,        // regex to remove characters
            lower: true,         // result in lower case
        })
    }

    if (props.match.groups.length) {

        return (
            <React.Fragment >
                <div key={props.index} id={`match-${props.match.id_match}`} className={`widget-default widget-forecastgroup ${classStatusMatch}`}>
                    {(props.match.user_points) ?
                        <span className="match__points">{props.match.user_points} pts.</span>
                        :
                        ''
                    }

                    <Row>
                        {(props.match.match_result) &&
                            <Col md="12" xs="12" className="widget-forecastgroup__date">
                                <span className="match-real__title">Resultado Final</span>
                            </Col>
                        }
                        <Col md="5" xs="4" className="widget-forecastgroup__local">
                            <img src={props.match.home_image} alt={props.match.home} />
                            <h6>{props.match.home_codename}</h6>
                        </Col>

                        <Col md="2" xs="4" className="widget-forecastgroup__guess" data-match-id={props.match.id_match} data-match-text={props.match.match}>
                            <div className="user-input">
                                <Input
                                    type="tel"
                                    min="0"
                                    maxLength="2"
                                    name="input-home"
                                    placeholder=""
                                    onClick={props.handleFocus}
                                    onKeyUp={onkeyup}
                                    defaultValue={scoreHome}
                                    disabled={true}

                                />
                                <Input
                                    type="tel"
                                    min="0"
                                    maxLength="2"
                                    name="input-away"
                                    placeholder=""
                                    onClick={props.handleFocus}
                                    onKeyUp={onkeyup}
                                    defaultValue={scoreAway}
                                    disabled={true}
                                />
                            </div>
                        </Col>
                        <Col md="5" xs="4" className="widget-forecastgroup__away">
                            <img src={props.match.away_image} alt={props.match.away} />
                            <h6>{props.match.away_codename}</h6>
                        </Col>

                    </Row>

                </div>

                {// eslint-disable-next-line
                    props.match.groups.map((one, index) => {

                        if ((one.ranking.length)) {


                            return (
                                <div key={index} id={`match-${props.match.id_match}`} className="widget-default widget-listgroups">
                                    <h3 className="widget-listgroups__title">
                                        <img src={one.avatar_group} alt="Logo del grupo" />
                                        {one.group}
                                    </h3>
                                    <React.Fragment >
                                        <ul>
                                            <li>
                                                <div className="col-2">Pos.</div>
                                                <div className="col-5">Usuario</div>
                                                <div className="col-3">Pred.</div>
                                                <div className="col-2">Pts.</div>
                                            </li>
                                            {
                                                one.ranking.map((two, index) => {
                                                    var classUserLoged = ''

                                                    if (props.userId === two.id) classUserLoged = 'class_user_loged'
                                                    return (
                                                        <li key={index}>
                                                            <div className="col-2">{index + 1}</div>
                                                            <div className={`col-5 widget-listgroups__user ${classUserLoged}`}>
                                                                <img src={two.avatar} alt={`Avatar ${two.username}`} />
                                                                <span>{two.username}</span>
                                                            </div>
                                                            <div className="col-3">{two.user_result}</div>
                                                            <div className="col-2">{two.user_points}</div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </React.Fragment>
                                </div>

                            )
                        }
                    })}


            </React.Fragment >
        )

    } else {
        return (
            <React.Fragment >
                <div key={props.index} id={`match-${props.match.id_match}`} className={`list-result ${classStatusMatch}`}>

                    <Row className="match__inside">
                        <h1>No tienes grupos asignados todavia</h1>
                        <Card className="component-ranking__leave_groups go_groups" onClick={goGroups}>
                            <CardBody>
                                Ir a Grupos
                            </CardBody>
                        </Card>

                    </Row>

                </div>

            </React.Fragment >
        )
    }


}

export default SingleForecastGroup;