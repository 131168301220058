import React, { useState } from 'react';
import { Col, Row, CustomInput } from 'reactstrap';
import { FaCaretUp, FaCaretDown, FaEquals } from "react-icons/fa";
import './Streak.css'

function Streak(props) {
    const [toggleView, setToggleView] = useState(true);

    const toggleViewHandler = () => {
        setToggleView(prevState => {
            return !prevState
        })

    }

    return (
        <div id={`match-home-${props.match.id_match}`} className="widget-default widget-streak">
            <div className="widget-streak__special-title container">
                <h3><img src={props.teamLogo} alt={`logo ${props.teamName}`} /> {props.teamName}, últimos partidos </h3>
                <CustomInput
                    type="switch"
                    id={`toggle-view__${props.teamLocation}`}
                    name="customSwitch"
                    onChange={toggleViewHandler}
                />
            </div>
            {(toggleView) ?
                <Row className="widget-streak__container">
                    {props.streaks.map((one, index) => {
                        var teamLocation
                        var teamLocationName
                        if (one['home'].nameCode === props.codename) {
                            teamLocation = one['visitor']
                            teamLocationName = 'Local'
                        } else {
                            teamLocation = one['home']
                            teamLocationName = 'Visita'
                        }
                        return (
                            <Col key={index} className={`widget-streak__team result_${props.codeStreak[index]}`}>
                                <small>{teamLocationName}</small>
                                <span>VS</span>
                                <img src={teamLocation.image} alt="" />
                                <h6>{one.result}</h6>
                            </Col>
                        )


                    })}
                </Row>
                :
                <div className="widget-streak__row container">
                    {props.streaks.map((one, index) => {
                        var teamLocationName
                        let resultColor
                        if (props.codeStreak[index] === 'P') {
                            resultColor = <FaCaretDown className="negative-icon" size="22"/>
                        } else if (props.codeStreak[index] === 'G') {
                            resultColor = <FaCaretUp className="positive-icon" size="22"/>
                        } else {
                            resultColor = <FaEquals className="equal-icon" size="16"/>
                        }
                        if (one['home'].nameCode === props.codename) {
                            teamLocationName = 'Local'
                        } else {
                            teamLocationName = 'Visitante'
                        }
                        return (
                            <Row key={index} className={`result_${props.codeStreak[index]}`}>
                                <Col xs="12">
                                    <small className="col-4">{teamLocationName}</small>
                                    <div  className="col-2"><img src={one['home'].image} alt=""/></div>
                                    <span className="col-2">{one.result}</span>
                                    <div  className="col-2"><img src={one['visitor'].image} alt=""/></div>
                                    <div className="col-2">{resultColor}</div>
                                </Col>
                            </Row>
                        )


                    })}
                </div>
            }
        </div>
    )

}

export default Streak;