import React from 'react';

import './TeamStreak.css'
import { Col, Row, Form } from 'reactstrap';
import { FaCaretUp, FaCaretDown, FaEquals } from "react-icons/fa";

import TeamsHistory from './TeamsHistory';
import Streak from './Streak';
import StreakClash from './StreakClash';
const slugify = require('slugify')


function TeamStreak(props) {
    var classStatusMatch = null

    var signalHome = <FaEquals />
    var signalAway = <FaEquals />
    var classlHome = 'same_shape'
    var classlAway = 'same_shape'


    if (props.match.stats.fixture[0].position > props.match.stats.fixture[0].last_position) {
        signalHome = <FaCaretDown size="22"/>
        classlHome = 'bad_shape'
    } else if (props.match.stats.fixture[0].position < props.match.stats.fixture[0].last_position) {
        signalHome = <FaCaretUp size="22"/>
        classlHome = 'good_shape'
    }

    if (props.match.stats.fixture[1].position > props.match.stats.fixture[1].last_position) {
        signalAway = <FaCaretDown size="22"/>
        classlAway = 'bad_shape'
    } else if (props.match.stats.fixture[1].position < props.match.stats.fixture[1].last_position) {
        signalAway = <FaCaretUp size="22"/>
        classlAway = 'good_shape'
    }

    if (props.match.user_status === 'No Played') {
        console.log('props.match.status_prediction', props.match.status_prediction);
    }


    if (props.match.user_status) {
        classStatusMatch = slugify(props.match.user_status, {
            replacement: '_',    // replace spaces with replacement
            remove: null,        // regex to remove characters
            lower: true,         // result in lower case
        })
    }

    var show_clash = false
    try {
        let temp_clash = props.match.stats.clash[0].matches[0]
        show_clash = temp_clash.length
    } catch (error) {

    }
    return (
        <React.Fragment >
            <div key={props.index} id={`match-${props.match.id_match}`} className={`widget-default widget-teamstreak ${classStatusMatch}`}>
                <Form id={`${props.match.id_match}`} autoComplete="off" >
                    <Row>
                        <Col md="5" xs="4" className="widget-teamstreak__local">
                            <img src={props.match.home_image} alt={props.match.home} />
                            <h6>{props.match.home_codename}</h6>
                            <small>Puesto</small>
                            <span className={`${classlHome}`}> No. {props.match.stats.fixture[0].position} {signalHome}</span>
                        </Col>

                        <Col md="2" xs="4" className="widget-teamstreak__result" data-match-id={props.match.id_match} data-match-text={props.match.match}>
                            <span>VS </span>
                        </Col>

                        <Col md="5" xs="4" className="widget-teamstreak__away">
                            <img src={props.match.away_image} alt={props.match.away} />
                            <h6>{props.match.away_codename}</h6>
                            <small>Posición</small>
                            <span className={`${classlAway}`}> No. {props.match.stats.fixture[1].position} {signalAway}</span>
                        </Col>

                    </Row>
                </Form>
            </div>

            <Streak
                {...props}
                streaks={props.match.stats.streak[0].matches[0]}
                codeStreak={props.match.stats.streak[0].code}
                codename={props.match.home_codename}
                teamLogo={props.match.home_image}
                teamName={props.match.home}
                teamLocation="home"
                />

            <Streak
                {...props}
                streaks={props.match.stats.streak[1].matches[0]}
                codeStreak={props.match.stats.streak[1].code}
                codename={props.match.away_codename}
                teamLogo={props.match.away_image}
                teamName={props.match.away}
                teamLocation="away"
            />

            {(show_clash) ?
                <StreakClash
                    {...props}
                    streaks={props.match.stats.clash[0].matches[0]}
                    codeStreak={props.match.stats.clash[0].code}
                    teams={`${props.match.home_codename} vs ${props.match.away_codename}`}
                />
                :
                ''
            }

            {/* <TeamsHistory match={props.match} premium={false}/> */}
            <TeamsHistory match={props.match} premium={true}/>


        </React.Fragment >
    )


}

export default TeamStreak;