import React from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { FaSync, FaChevronRight, FaLock, FaSignOutAlt, FaBan } from "react-icons/fa";
import './ProfileForm.css';

const ProfileForm = props => {

  return (
    <Form className="profile-form">
      <div className="profile-avatar" onClick={props.onPressImg}>
        <img className="profile-img-avatar" src={props.user.avatar} alt="avatar" />
        <div className="profile-avatar__randomize">
          <FaSync size="20" />
        </div>
      </div>
      <FormGroup>
        <Label for="inputNombre">Nombre:</Label>
        <Input type="text" autoComplete="name" name="name" id="name" placeholder="Nombre" value={props.user.name} onChange={props.changeHandler} />
      </FormGroup>
      <FormGroup>
        <Label for="inputUsuario">Usuario</Label>
        <Input type="text" autoComplete="user" name="username" id="username" maxLength="15" placeholder="Usuario" value={props.user.username} onChange={props.changeHandler} />
      </FormGroup>
      <FormGroup className="profile-form__locked">
        <Label for="inputEmail">Email: </Label>
        <FaBan size="20"/>
        <Input type="email" autoComplete="email" name="email" placeholder="Email" value={props.user.email} onChange={props.changeHandler} disabled />
      </FormGroup>

      <div>

        <Button onClick={props.onPressChangePw} className="profile-password">
          <FaLock size="25" />
          <div className="profile-button__description">
            <span>Cambiar Contraseña</span>
            <span className="span-info">Si necesitas cambiar la contraseña...</span>
          </div>
          <FaChevronRight size="15" />
        </Button>
      </div>
      <Button onClick={props.onPressLogout} className="profile-logout">
        <FaSignOutAlt size="25" />
        <div className="profile-button__description">
          <span>Cerrar sesión</span>
          <span className="span-info">Cierra tu sesión de FPD Prode</span>
        </div>
        <FaChevronRight size="15" />

      </Button>
      <Button onClick={props.onPressUpdate} className="profile-save" disabled={props.btnDisabled}>Guardar cambios</Button>
    </Form>

  )



}

export default ProfileForm