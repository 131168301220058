import React, { useState, useEffect } from 'react';
import './CountDown.css'

const CountDown = props => {

  const [dateCD, setDateCD] = useState({
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
  })
  let interval = null
  let currentDate = null
  useEffect(() => {
    // eslint-disable-next-line
    interval = setInterval(() => {
      if (props.currentDate) {
        if (currentDate) {
          currentDate.setSeconds(currentDate.getSeconds() + 1)
        } else {
          // eslint-disable-next-line
          currentDate = new Date(props.currentDate);
        }
      } else {
        if (currentDate) {
          currentDate.setSeconds(currentDate.getSeconds() + 1);
        } else {
          currentDate = new Date();
        }
      }

      const date = calculateCountdown(currentDate, props.endDate);
      date ? setDateCD(date) : stop();

    }, 1000)
    return () => {
      stop();
    };
  }, [props.currentDate, currentDate])


  const calculateCountdown = (currentDate, endDate) => {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date(currentDate))) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) { // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) { // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  const stop = () => {
    let fin = {
      days: 0,
      hours: 0,
      millisec: 0,
      min: 0,
      sec: 0,
      years: 0
    }
    setDateCD(fin)
    clearInterval(interval);
    if (props.onFinishCd) {
      props.onFinishCd();
    }
  }

  const addLeadingZeros = (value) => {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
  }

  return (
    <div className="widget-default widget-counter">
      <h6>{props.title}</h6>
      <ul>
        <li>
          <span>{addLeadingZeros(dateCD.days)}</span>
          <span>d</span>
        </li>
        <li>
          <span>{addLeadingZeros(dateCD.hours)}</span>
          <span>h</span>
        </li>
        <li>
          <span>{addLeadingZeros(dateCD.min)}</span>
          <span>m</span>
        </li>
        <li>
          <span>{addLeadingZeros(dateCD.sec)}</span>
          <span>s</span>
        </li>
      </ul>
    </div>
  );

}

export default CountDown