import React, { useState, useEffect } from 'react';
// import { getRanking, getRankingStage } from '../store/actions/ranking'
import { leaveGroup } from '../store/actions/user'

import { useSelector, useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router';
import { validationHandleError, customError } from '../store/actions/utils'

import Rankings from '../components/Ranking/Ranking'
import api from '../api'
import './styles/Ranking.css';

import { Container, Card, CardBody, Spinner } from 'reactstrap';

const Ranking = props => {
  const [idGroup, setidGroup] = useState();
  const [currentstage, setcurrentstage] = useState();
  const [ranking, setranking] = useState([]);
  const [currentGroup, setcurrentGroup] = useState('');
  const [loading, setloading] = useState(true);
  const [loadingJornada, setloadingJornada] = useState(true);
  const [byjornada, setbyjornada] = useState(true);
  const { history, location } = useReactRouter();

  const reducRanking = useSelector(state => {
    return {
      ranking: state.ranking,
      tournament: state.tournament.tours,
      user: state.user
    }
  })

  const dispatch = useDispatch()
  
  if(!location.state){
    
    history.push('/')
  }

  useEffect(() => {

    let executeFunc = async () => {
      setidGroup(location.state.idGroup)
      try {
        setloading(true);
        setloadingJornada(true);
        let getGroup = reducRanking.user.groups.find(x => x.id_group === location.state.idGroup)
        setcurrentGroup(getGroup);
        // dispatch(getRanking({ idGroup: location.state.idGroup }))
        // console.log('getGroup.id_tournament', getGroup.id_tournament);
        var data0 = await api.ranking.get(location.state.idGroup);
        // console.log("data0 ", data0)
        if (data0.statusCode === 200) {
          let to_load = {
            ranking : data0.result,
            TourEnded: false,
            current_point: reducRanking.tournament[getGroup.id_tournament].current_point,
            instances: reducRanking.tournament[getGroup.id_tournament].instances + 1,
            current_instance: reducRanking.tournament[getGroup.id_tournament].current_instance,
          }
          if (!reducRanking.tournament[getGroup.id_tournament].status_tournament === 1) {
            to_load['TourEnded'] = true
          }
          setranking(to_load)
          var currstage = to_load.current_instance
          if (location.state.stage){
            currstage = location.state.stage
          }
          setcurrentstage(currstage)
        }else{
          customError.prototype = Error.prototype;
          const message = (data0.message || data0.result.message)
          // eslint-disable-next-line
          throw { name: (data0.error || ''), message: message, status: data0.status };
        }
        // dispatch(getRankingStage({ idGroup: location.state.idGroup, stage: currstage }))
        var data = await api.ranking.getbyStage(location.state.idGroup, currstage);
        // console.log('datadatadata', data);

        if (data.statusCode === 200) {
          let to_load = {
            ranking: data.result,
            TourEnded: false,
            current_point: reducRanking.tournament[getGroup.id_tournament].current_point,
            instances: reducRanking.tournament[getGroup.id_tournament].instances + 1,
            current_instance: reducRanking.tournament[getGroup.id_tournament].current_instance,
          }
          if (!reducRanking.tournament[getGroup.id_tournament].status_tournament === 1) {
            to_load['TourEnded'] = true
          }
          setbyjornada(to_load)
          setloadingJornada(false)
        } else {
          customError.prototype = Error.prototype;
          const message = (data.message || data.result.message)
          // eslint-disable-next-line
          throw { name: (data.error || ''), message: message, status: data.status };
        }
        
        setloading(false);
        
      } catch (error) {
        setloading(false);
        
        // console.log('errorerrorerrorerror', error);
        validationHandleError(error)
      }
    }

    if(location.state){
      executeFunc();
    }

  }, [reducRanking.user.groups, reducRanking.tournament, location.state])

  const leaveGroupHandler = () => {
    dispatch(leaveGroup({ ...currentGroup, approvalStatus: 4 }))
    
    setTimeout(function () {
      history.replace('/grupos')
    }, 1000)
  } 
  
  const changeStageRanking = async (event) => {
    setloadingJornada(true)
    let newStage = event.target.getAttribute('datavalue')
    
    setcurrentstage(newStage)
    var data = await api.ranking.getbyStage(idGroup, newStage);
    if (data.status) {
      let to_load = {
        ranking: data.result,
        TourEnded: false,
        current_point: reducRanking.tournament[currentGroup.id_tournament].current_point,
        instances: reducRanking.tournament[currentGroup.id_tournament].instances + 1,
        current_instance: reducRanking.tournament[currentGroup.id_tournament].current_instance,
      }
      if (!reducRanking.tournament[currentGroup.id_tournament].status_tournament === 1) {
        to_load['TourEnded'] = true
      }
      setbyjornada(to_load)
      setloadingJornada(false)
    }
  }

  // console.log('ranking.length', ranking.length);
  
  if (loading){ return <Spinner style={{ width: '3rem', height: '3rem' }} />}
    else {

    
    return (
      <React.Fragment >

        <Container className="ranking cf text-center">
          <Rankings 
            className="component-ranking" 
            ranking={ranking} 
            totalstage={ranking.instances} 
            currentstage={currentstage} 
            currentpoint={ranking.current_point} 
            jornada={byjornada.ranking} 
            changeStageRanking={changeStageRanking} 
            loadingJornada={loadingJornada}
          />
        </Container>
        <p className="span-info leave_groups-info">Si deseas salir de este grupo puedes abandonarlo</p>
        <Card className="component-ranking__leave_groups" onClick={leaveGroupHandler}>
          <CardBody>
            Abandonar grupo
          </CardBody>
        </Card>
      </React.Fragment >
    )
  }

}

export default Ranking;