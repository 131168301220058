import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import './DropDownList.css'

const DropDownList = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);


  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        {props.current}
        </DropdownToggle>
      <DropdownMenu>

        {props.list.map((ele, index) => {
          return (<DropdownItem className={(props.current == ele.label)? 'selected': ''} key={ele.index} datavalue={ele.index} onClick={(e)=>props.onClick(e)}>{ele.label}</DropdownItem>)
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

export default DropDownList;