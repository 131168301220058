import React, { useState, useEffect } from 'react';
import { getForecastHelp } from '../store/actions/forecasthelp'
import { useSelector, useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router';

import TeamStreak from '../components/ForescastHelp/TeamStreak';

import { Container, Row, Col, Spinner } from 'reactstrap';


const ForescastHelp = props => {
  const [loading, setloading] = useState(true);
  const [IdMatch, setIdMatch] = useState(true);

  const { history, location } = useReactRouter();
  const reducer = useSelector(state => {
    return {
      ForecastHelp: state.forecasthelp,
      userId: state.user.id
    }
    
  })

  const dispatch = useDispatch()

  useEffect(() => {

    let executeFunc = async () => {
      if (!location.state) {
        history.push('/pronosticos')
      }

      try {

        setloading(true);
        setIdMatch(location.state.matchId)
        setloading(true);

        dispatch(getForecastHelp({ idMatch: location.state.matchId}))
        // setdataLoad(produc.result);

        setloading(false);
      } catch (error) {
        setloading(false);

        console.log(error);
      }
    }
    executeFunc();
    // eslint-disable-next-line
  }, [])


  if (loading) return <Spinner style={{ width: '3rem', height: '3rem' }} />

  if (reducer.ForecastHelp.loading || !reducer.ForecastHelp.matches) {
    return <Spinner style={{ width: '3rem', height: '3rem' }} />
  } else {


    // setmyselector(sel);
    return (
      <React.Fragment >
        {(reducer.ForecastHelp.matches) ?
          <Container className="match-component">
            <Row>
              <Col>
                <TeamStreak match={reducer.ForecastHelp.matches[`match${IdMatch}`]} userId={reducer.userId} />
                {/* <span className="span-info">* Los usuarios que no aparecen no jugaron este partido</span> */}
              </Col>
            </Row>
          </Container>
          :
          ''
        }
      </React.Fragment >
    )
  }



}

export default ForescastHelp;