import React, { useEffect } from 'react'
import TournamentList from '../components/TournamentList/TournamentList'

import useReactRouter from 'use-react-router';
import { getTournaments } from '../store/actions/tournament'
import { useSelector, useDispatch } from 'react-redux'
import { Spinner } from 'reactstrap';

const slugify = require('slugify')

const Tournaments = props => {

  const reducTournament = useSelector(state => {

    return {
      tournament: state.tournament,
      user: state.user
    }
  })

  const dispatch = useDispatch()

  const { history } = useReactRouter();

  useEffect(() => {
    let executeFunc = async () => {
      try {
        dispatch(getTournaments())

      } catch (error) {
        
        console.log(error);
      }
    }
    executeFunc();
    
  }, [dispatch])


  const groupClickHandler = (id,tournament) => {
    let slug = slugify(tournament, {
      replacement: '__',    // replace spaces with replacement
      remove: null,        // regex to remove characters
      lower: true,         // result in lower case
    })
    history.push(`/pronosticos/${slug}`, { idtournament: id, title: tournament })
    
  }

  if (reducTournament.tournament.loading) {
    return <Spinner style={{ width: '3rem', height: '3rem' }} />
  } else {
    let toursArray = []
    // eslint-disable-next-line
    Object.keys(reducTournament.tournament.tours).map((index) => {
      // console.log('reducTournament.tournament.tours[index].status_tournament', reducTournament.tournament.tours[index].status_tournament);
      if (reducTournament.tournament.tours[index].status_tournament === 1){
        toursArray.push(reducTournament.tournament.tours[index])
      }
    })
    return (
      <div className="groups-container pronosticos-list" >
        <TournamentList data={toursArray} id="id_tournament" itemName="tournament" title="Tus Torneos" clickHandler={groupClickHandler} />
      </div>
    )
  }

  

}

export default Tournaments