import {
  GET_FORECASTGROUP, 
  LOADING, 
  ERROR 
} from '../types/forecastgroupTypes'

import { validationHandleError, customError } from './utils'
import api from '../../api';

export const getForecastGroup = ({ idMatch}) => async (dispatch, getState) => {
  
  dispatch({
    type: LOADING
  })

  try {
    const { forecastgroup } = getState();
    var response = forecastgroup.matches[`match${idMatch}`]
    if (!response) {
      var data = await api.forecastgroup.get(idMatch);
      
      if (data.statusCode !== 200) {
        customError.prototype = Error.prototype;
        const message = (data.message || data.result.message)
        // eslint-disable-next-line
        throw { name: (data.error || ''), message: message, status: data.status };
      }
      response = data.result
    } 
    dispatch({
      type: GET_FORECASTGROUP,
      payload: response
    })

  } catch (e) {
    let shouldDisptach = validationHandleError(e)
    if (shouldDisptach) {
      dispatch({
        type: ERROR
      })
    }
  }
  
} 