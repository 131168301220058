import React from 'react'
import './UserStats.css'
import './swiper.css'
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import Swiper from 'react-id-swiper';

// const Flickity = require("react-flickity-component");

const UserStats = props => {
  let hasData = props.data.length > 0
  const params = {
    slidesPerView: 'auto',
    containerClass: "stats-container",
    spaceBetween: 20,
  }
  // console.log(props)
  if(hasData){
    return (
      <React.Fragment>
        <Swiper {...params}>
          {props.data.map((userStat, index) =>
            <div key={index} className={`stats-widget widget-template__accuracy ${userStat.classCss}`}>
              <div className="stats-widget__title">
                <p>{userStat.title}</p>
              </div>
              <div className="stats-widget__legend">
                <p>{userStat.legend}</p>
              </div>
              <div className="stats-widget__values">
                <span><img src={userStat.icon} alt=""/></span>
                <h1>{userStat.body}</h1>
              </div>
            </div>

          )}
        </Swiper>
      </React.Fragment>
      
    )
  }else{
    return (<span>No hay estadísticas disponibles</span>)
  }

}

export default UserStats