import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import App from './components/App';
import './index.css'

import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';

import reducers from './store/reducers/'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__|| compose;

const store = createStore(
	reducers, //Todos los reducers
	{}, //Estado Inicial
	composeEnhancers(applyMiddleware(reduxThunk))
)

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(
// 	reducers, //Todos los reducers
// 	{}, //Estado Inicial
// 	composeEnhancers(applyMiddleware(reduxThunk))
// )

ReactDOM.render(
	<Provider store={ store }>
		<App />
	</Provider>,
document.getElementById('app')
);