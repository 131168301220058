import {
  UPDATE_MENU, 
  LOADING, 
  ERROR 
} from '../types/uiTypes'

const INITIAL_STATE = {
  menu: [],
  loading: false,
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return { 
        ...state, 
        loading: true,
        error:false
      }
    case ERROR:
      return { 
        ...state, 
        error: action.payload,
        loading: false
      }
    case UPDATE_MENU: 
      return {
        ...state, 
        menu: [...action.payload],
        loading:false,
        error:false
      }
    default: return state;
  }
}