import React from 'react';

import { Col, Row, Input, Form, FormGroup, Label } from 'reactstrap';
import useReactRouter from 'use-react-router';
import './ForecastPenalties.css'



function ForecastPenalties(props) {
    const { history } = useReactRouter();

        return (

            <React.Fragment >
                {[1, 2, 3, 4, 5].map((ele, index) => {

                    return (<div key={`penalties-${index}`} id={`penalties-${index}`} className="widget-default widget-streak">
                        <div className="widget-streak__special-title container">

                            <Form id={``} autoComplete="off" >
                                <Row>
                                    <Col className="widget-matchpredict__step">
                                        <span>{index + 1} º</span>
                                    </Col>
                                    <Col className="widget-matchpredict__penalties" >
                                        <FormGroup tag="fieldset">

                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="radio" name="step_home_1"

                                                    />{' '}
                                                </Label>
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col className="widget-matchpredict__penalties" >
                                        <FormGroup tag="fieldset">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="radio" name="step_home_1"

                                                    />{' '}
                                                </Label>
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col className="widget-matchpredict__penalties" >
                                        <FormGroup tag="fieldset">
                                            <FormGroup check>

                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col className="widget-matchpredict__penalties" >
                                        <FormGroup tag="fieldset">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="radio" name="step_away_1"

                                                    />{' '}
                                                </Label>
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col className="widget-matchpredict__penalties" >
                                        <FormGroup tag="fieldset">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="radio" name="step_away_1"

                                                    />{' '}
                                                </Label>
                                            </FormGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>)

                })}

            </React.Fragment>
        )


}

export default ForecastPenalties;