import React from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import './ProfileForm.css';

const ChangePassword = props => {
  
  return(
    <Form className="profile-chnpassword">
      <FormGroup>
        <Label for="inputNombre">Nueva Contraseña: </Label>
        <Input type="password" autoComplete="new-password" name="newPassword" id="password" placeholder="*******" value={props.password.newPassword} onChange={props.changeHandler} />
      </FormGroup>
      <FormGroup>
        <Label for="inputUsuario">Confirmar Contraseña: </Label>
        <Input type="password" autoComplete="new-password" name="confirmPassword" id="confirmPassword" placeholder="*******" value={props.password.confirmPassword} onChange={props.changeHandler} />
        <Label style={{color:'red'}}>{props.password.error} </Label>
      </FormGroup>

      <Button onClick={props.onPressUpdate} className="profile-save"> Confirmar cambios </Button>
      <Button onClick={props.onPressCancel} className="profile-none">Cancelar</Button>
    </Form>

  )

}

export default ChangePassword