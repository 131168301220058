import React, { useState, useEffect } from 'react';
import useReactRouter from 'use-react-router';

import { Spinner, Alert } from 'reactstrap';
import UserStats from '../components/UserStats/UserStats'
import UserMenu from '../components/UserMenu/UserMenu'

import { getHomeMenu } from '../store/actions/ui'
import { getUserStats } from '../store/actions/user'
import { useSelector, useDispatch } from 'react-redux'
import './styles/Home.css';

function Home() {
  const { history } = useReactRouter();
  const dispatch = useDispatch()
  const [showAlert, setShowAlert] = useState(false)
  const [ShowModal, setShowModal] = useState(false);

  const objReducer = useSelector(state => {
    return {
      uiMenu: state.ui.menu,
      uiLoading: state.ui.loading,
      uiError: state.ui.error,
      userName: state.user.name,
      avatar: state.user.avatar,
      wizard: state.user.wizard,
      userStats: state.user.stats,
      userLoading: state.user.loading,
      isLoggedIn: state.user.isLoggedIn,
      idUser: state.user.id,
      userError: state.user.error,
    }
  })

  console.log("HISTORYYYYY ", history)

  useEffect(() => {
    dispatch(getHomeMenu())
    if (objReducer.isLoggedIn) {
      dispatch(getUserStats(objReducer.idUser))
    }

  }, [dispatch,objReducer.idUser,objReducer.isLoggedIn])

  useEffect(() => {
    if (objReducer.uiError && !showAlert) {
      setShowAlert({ color: "danger", message: "Tuvimos un problema al cargar el menu" })
    }
    if (objReducer.userError && !showAlert) {
      setShowAlert({ color: "danger", message: "Tuvimos un problema al cargar las estadisticas" })
    }
  }, [objReducer.uiError, objReducer.userError,showAlert])

  const routeHandler = (route, clickable = true, title = null) => {
    if (clickable) {
      let objParam = null
      if(title){
        objParam = {title:title}
      }
      history.push(route, objParam)
    }
  }
  if (objReducer.uiLoading) {
    return <Spinner style={{ width: '3rem', height: '3rem' }} />
  }

  const openModal = () => {
    setShowModal(true)
  }

  const resetModal = () => {
    setShowModal(false)
  }

  let errorMessage = null;
  if (showAlert) {
    errorMessage = (
      <Alert color={showAlert.color}>
        {showAlert.message}
      </Alert>
    )
  }
  if (objReducer.wizard === 1) {
    history.push('/wizard')
  }

  return (
    <React.Fragment>

      <div className="resultados-container">
        <div className="menu-header">
          <h1>
            <img src={objReducer.avatar} alt={`Avatar ${objReducer.userName}`}/>
            Hola {objReducer.userName}
          </h1>
        </div>
        {objReducer.userLoading ? <Spinner style={{ width: '3rem', height: '3rem' }} /> : <UserStats data={objReducer.userStats} />}
        <UserMenu data={objReducer.uiMenu} itemToRoute={routeHandler} openModal={openModal} resetModal={resetModal} ShowModal={ShowModal}/>
        {errorMessage}
      </div>
    </React.Fragment>
  )
}

export default Home;