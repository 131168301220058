import React from 'react';
import { Container,Row, Col } from 'reactstrap';
import './Footer.css'


class FooterCustom extends React.Component {

  render() {
    return (
      
        <Container className="footer">
          <div className="footer-bg" style={{backgroundImage:"url(/footer-background.png)"}}></div>
          <Row>
            <Col>
              {/* <img src="/brand_192x192.png" alt="logo"/> */}
              {/* <span>FPD PRODE @ 2019</span> */}
            </Col>
          </Row>
        </Container>
    );

  }
}
export default FooterCustom;