import React from 'react';
import useReactRouter from 'use-react-router';
// import { useSelector, useDispatch } from 'react-redux'

import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'

// import { useSubscribeReduxSocket } from '../hooks/useSubscribeSocket'

// { console.log(this.props.location.pathname);}
const Layout = props => {
  let pathName = props.location.pathname.replace(/[/\\*]/g, " ")
  const { history, location } = useReactRouter();
  // const dispatch = useDispatch()

  // const userReducer = useSelector(state => {

  //   return {isLoggedIn: state.user.isLoggedIn, userName: state.user.name, sessionChecked: state.user.sessionChecked }
  // })

  // const [socketConnect, objPushNotification, errorSocket] = useSubscribeReduxSocket('pepe')

  // useEffect(() => {
  //   if(userReducer.isLoggedIn){
  //     console.log("conecto al socket")
  //     socketConnect()

  //   }
  //   return () => {
  //     console.log("holaaaaa1111")
  //   }
  //   console.log("recibo notificacion de socket, veo que hago!.")
  // }, [userReducer.isLoggedIn, objPushNotification, errorSocket])

  const goBackHandler = () => {
    if (history.action === "POP") {
      history.push('/')
    } else {
      const path = (location.state ? location.state.route : null)
      history.goBack(path)
    }
  }
  return (
    <React.Fragment>
      <main role="main" className={pathName}>
        <Navbar goBack={goBackHandler} screen={location.pathname} />
        <div className="row justify-content-center" style={{ margin: 0 }}>
          <div style={{ minHeight: '90vh', padding: 0 }} className="col-lg-6 col-xs-12 col-md-6">
            {props.children}
          </div>
        </div>
        <Footer></Footer>
      </main>
    </React.Fragment>
  )

}
export default Layout