export const LOADING = 'user_loading';
export const ERROR = 'user_error';
export const CLEAN_ERROR = 'user_clean_error';
export const GET_STATS = 'get_stats';
export const SET_SESSION = 'set_session';
export const UPDATE_SESSION = 'update_session';
export const CHECK_SESSION = 'check_session';
export const LOGOUT = 'logout';
export const CREATE_GROUPS = 'create_groups';
export const ADD_GROUPS = 'add_groups';
export const SET_NOTIFICATIONS = 'set_notifications'
export const LEAVE_GROUP='leave_group'
export const UPDATE_REQUEST='user_update_request'