import React from 'react'
import { Button } from 'reactstrap';
import { FaChevronRight, FaTrash } from "react-icons/fa";

import './GroupList.css'

const GroupList = props => {
  const NoIcon = props => {
    return <div></div>
  }

  const buttonList = {
    FaTrash: FaTrash,
    FaChevronRight: FaChevronRight,
    NoIcon: NoIcon
  }
  let bodyInfo = <span className='span-info'>{props.message}</span>

  if (props.data.length > 0) {
    let HandlerButton = buttonList[props.btnType];
    let removeButton = null;
    bodyInfo = props.data.map((item, index) => {
      removeButton = <HandlerButton onClick={props.btnOnClick ? () => props.btnOnClick(item) : undefined} className="display-list" />
      return (
        <div key={index} className={`user-groups ${props.btnOnClick ? 'user-groups__remove' : 'user-groups__invitation'}`}>
          <Button
            onClick={() => props.clickHandler(item[props.id], index)}
            disabled={( (props.btnOnClick ? true : false ) || item.btnDisable)}
            className={item.message ? 'user-groups__invitation-sent' : '' }
          >
            <div className="user-groups__meta">
              <img src={item.avatar_group} alt="avatar" />
              <h6>{props.defaultText} {item[props.itemName]}<span>Creado por {item.owner}</span></h6>
              {removeButton}
            </div>
            <div className="user-groups__info">
              <span>Código: {item.token}</span>
              <span>{item.members} {item.members > 1 ? 'miembros' : 'miembro'} </span>
              {/* props de mensaje */}
              {item.message ? <span className="user-groups__info-pending">{item.message}</span> : null}
            </div>
          </Button>
        </div>)
    }
    )
  }

  return (
    <div className="gridlist_container">
      <h6>{props.title}</h6>
      {bodyInfo}
    </div>
  )
}

GroupList.defaultProps = {
  loading: false,
  defaultText: '',
  btnType: 'FaChevronRight',
  btnOnClick: false,
  detail: []
}

export default GroupList