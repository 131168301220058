import React from 'react'
import { Spinner, Button, Container, Row, Col } from 'reactstrap';
import './TournamentList.css';

const TournamentList = props => {

  let bodyInfo = <Spinner className="spinner-stats" color="dark" size="sm" />
  if (!props.loading) {
    bodyInfo = props.data.map((item, index) =>
      (<Button
        id={`tournament_${item[props.id]}`}
        className={`tournament_${item['coi']}`}
        key={index}
        size="lg"
        onClick={() => props.clickHandler(item[props.id], item['tournament'])}
        block
      >
        <img src={item['image_tournament']} alt={item[props.itemName]} className="tournament-icon"/>
        {props.defaultText} {item[props.itemName]}
      </Button>)
    )
  }

  return (
    <Container className="gridlist_container pronosticos-list tournamentList">
      <Row>
        <Col>
          <h6>{props.title}</h6>
          {bodyInfo}
        </Col>
      </Row>
    </Container>
  )
}

TournamentList.defaultProps = {
  loading: false,
  defaultText: ''
}

export default TournamentList