import React from 'react'
import useReactRouter from 'use-react-router';
import './Navbar.css'

const svgIcon = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M34.52 239.03L228.87 44.7c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.5 256 285.5 410.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z" /></svg>)
const Navbar = props => {
  const { location } = useReactRouter();

  let goBackIcon = <div className="navbar_goback"></div>

  if (props.screen !== '/' && props.screen !== '/home' && props.screen !== '/ingresar' && props.screen !== '/wizard') {
    goBackIcon = <div className="navbar_goback" onClick={props.goBack}>{svgIcon}</div>
  }
  let arrPath = props.screen.split("/")

  // document.body.classList.add(`${forBodyClass}`);
  document.body.removeAttribute("class");

  // eslint-disable-next-line
  arrPath.map((ele) => {
    if (ele) document.body.classList.add(ele)
  })
  let pathPosition = arrPath.length - 1

  //Si me viene slug en el entorno lo utilizo, sino hago un decode de la url para mostrar algo 
  let section = null
  if (location.state) {
    section = location.state.title
  } else {
    section = (arrPath[pathPosition].length > 0 ? arrPath[pathPosition].split('__').join(' ') : "home")
  }

  let navSection = <div className="navbar_section">{section}</div>
  let homeNavBar = '';
  if (section === 'home' || section === 'wizard' || section === 'ingresar') {
    navSection = null;
    homeNavBar = 'disable_navbar';
  }


  return (
    <div className={`navbar_container ${homeNavBar}`}>
      {goBackIcon}
      {navSection}
    </div>
  )

}

export default Navbar