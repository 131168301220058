import React from 'react';
import { Spinner } from 'reactstrap';
import './UserMenu.css'

const ButtonHome = props => {

  return (
    <div className={`menu-item menu-grupos ${props.classCss}`} onClick={() => props.itemToRoute(props.route, props.action_click, (props.title || null))}>
      <div className="menu-item__background"><span></span></div>
      <div className="menu-item__container">
        <h2>{props.title}</h2>
      </div>
    </div>
  )
}

const BannerHome = props => {
  return (
    <div className={`menu-item-banner ${props.classCss}`} onClick={() => props.itemToRoute(props.route, props.action_click, (props.title || null))}>
      <img src={props.image} alt="banner" />
    </div>
  )
}


const objComponents = {
  button: ButtonHome,
  banner: BannerHome
}

const UserMenu = props => {

  // const OnConfirm = () => {

  //   props.resetModal()
  // }

  // const nextOnClick = () => {

  //   props.resetModal()
  // }

  let response = <Spinner className="spinner-stats" color="dark" size="sm" />
  if (props.data.length > 0) {
    response = props.data.map(item => {
      var HomeComponent = objComponents[(item.type || 'button')]
      return <HomeComponent key={item.id} {...item} {...props} />
    })
  }

  return (
    <div className="menu-items">
      {response}
    </div>

  )

}


export default UserMenu