import React, { useEffect, useState } from 'react';
import './SingleMatch.css'
import { Col, Row, Form, Input } from 'reactstrap';
import useReactRouter from 'use-react-router';

import { FaChevronRight } from "react-icons/fa";

const slugify = require('slugify')

function SingleMatch(props) {
    const [showLoading, setShowLoading] = useState(false)
    const [showError, setShowError] = useState(false)
    let Show = true
    let scoreHome = '';
    let scoreAway = '';
    let inputsDisabled = false
    let showPoints = false
    let forecastGroup = false
    let showMsgError = ''
    let errorFront = ''
    const { history } = useReactRouter();

    useEffect(() => {
        if (props.match.loading) {
            setShowLoading(true)
            setTimeout(() => {
                setShowLoading(false)
            }, 1000);
        }

    }, [props.match.loading])

    useEffect(() => {
        if (props.match.error) {
            setShowError(true)            
            setTimeout(() => {
                setShowError(false)
            }, 10000);
        }

    }, [props.match.error])

    // Handle Error
    if(props.match.error){
        console.log('props.match.errorprops.match.error', props.match.error);
        if (props.match.error['message'].indexOf("equal to 10") >= 0) {
            showMsgError = 'Solo se aceptan números menores de 10'
        } else if (props.match.error['message'].indexOf("Failed to fetch") >= 0) {
            showMsgError = 'Tuvimos problemas para enviar tu pronostico. Vuelve a intentarlo.'
        }else{
            console.log('props.match.error[message]', props.match.error['message']);
            showMsgError = 'Ha ocurrido un problema, intenta mas tarde.'
        }
    }

    if (props.match.user_result) {
        let spli_result = props.match.user_result.split('-')
        scoreHome = spli_result[0]
        scoreAway = spli_result[1]
    }

    if (props.match.status_prediction === 'Unavailable') {
        inputsDisabled = true
        if (props.match.match_event_win){
            showPoints = true
        }
        forecastGroup = true
        if (props.match.user_status === 'No Played') {
            scoreHome = '-'
            scoreAway = '-'
        }
    }

    function onkeyup(e) {
        let string = e.target.value
        var out = '';
        var filtro = '1234567890'; //Caracteres validos

        for (var i = 0; i < string.length; i++) {

            if (filtro.indexOf(string.charAt(i)) !== -1) {
                out += string.charAt(i);
                if (e.target.value > 10){
                    console.log('Mandar error');
                }else{
                    props.inputChange(e, props.index)
                }
            }

        }
        e.target.value = out;
    }

    function displaylist(matchId, matchText) {

        let slug = slugify(matchText, {
            replacement: '__',    // replace spaces with replacement
            remove: null,        // regex to remove characters
            lower: true,         // result in lower case
        })

        // console.log('match', slug);
        history.push(`/pronosticos/grupales/${slug}`, { matchId: matchId, title: matchText })

        // settogglelist(!togglelist)
        // if (togglelist) {
        //     let node_selector = document.getElementById(match_id)
        //     // setoffset(node_selector.offsetTop)
        //     if (last){
        //         console.log('last');
        //         setTimeout(() => {
        //             node_selector.scrollIntoView({ behavior: 'smooth', block: 'center'});

        //         }, 100);
        //     }else{
        //         node_selector.scrollIntoView({ behavior: 'smooth', block: 'center'});
        //     }
        // }
    }

    function displayhelp(matchId, matchText) {

        let slug = slugify(matchText, {
            replacement: '__',    // replace spaces with replacement
            remove: null,        // regex to remove characters
            lower: true,         // result in lower case
        })

        // console.log('match', slug);
        history.push(`/pronosticos/help/${slug}`, { matchId: matchId, title: matchText })

        // settogglelist(!togglelist)
        // if (togglelist) {
        //     let node_selector = document.getElementById(match_id)
        //     // setoffset(node_selector.offsetTop)
        //     if (last){
        //         console.log('last');
        //         setTimeout(() => {
        //             node_selector.scrollIntoView({ behavior: 'smooth', block: 'center'});

        //         }, 100);
        //     }else{
        //         node_selector.scrollIntoView({ behavior: 'smooth', block: 'center'});
        //     }
        // }
    }

    function displayDeatchMatch(matchId, matchText) {

        let slug = slugify(matchText, {
            replacement: '__',    // replace spaces with replacement
            remove: null,        // regex to remove characters
            lower: true,         // result in lower case
        })

        // console.log('match', slug);
        history.push(`/pronosticos/penales/${slug}`, { matchId: matchId, title: matchText })

        // settogglelist(!togglelist)
        // if (togglelist) {
        //     let node_selector = document.getElementById(match_id)
        //     // setoffset(node_selector.offsetTop)
        //     if (last){
        //         console.log('last');
        //         setTimeout(() => {
        //             node_selector.scrollIntoView({ behavior: 'smooth', block: 'center'});

        //         }, 100);
        //     }else{
        //         node_selector.scrollIntoView({ behavior: 'smooth', block: 'center'});
        //     }
        // }
    }

    if (props.match.user_result) {
        // console.log('props.match.user_result', props.match.user_result);
        // console.log('scoreHome', scoreHome);
        // console.log('scoreAway', scoreAway);
    }

    const classStatusMatch = slugify(props.match.user_status, {
        replacement: '_',    // replace spaces with replacement
        remove: null,        // regex to remove characters
        lower: true,         // result in lower case
    })
    // console.log('props.match.id_match', props.match.id_match)
    // console.log('props.match.user_points', props.match.user_points)

    var enableStreak = (
        <span className="span-info" style={{paddingBottom:"0.5rem"}}>* Todavía no hay ayuda disponible</span>
    )

    var enablePostorDeathMatch = (
        <div className="widget-matchpredict__help" onClick={() => displaylist(props.match.id_match, props.match.match)}>
            <span>Tus resultados en otros grupos</span><FaChevronRight size="14" />
        </div>
    )
    if (props.currentStage) {
        if (!inputsDisabled){
            enableStreak = (
                <div className="widget-matchpredict__help" onClick={() => displayhelp(props.match.id_match, props.match.match)}>
                    <span>¿Necesitas ayuda? Mirá la racha</span><FaChevronRight size="14" />
                </div >
            )
        }else{
            enablePostorDeathMatch = ''
            enableStreak = ''
            // if (props.match.live) {
            //     enablePostorDeathMatch = (
            //         <div className="widget-matchpredict__deathmatch" onClick={() => displayDeatchMatch(props.match.id_match, props.match.match)}>
            //             <span>Death Match</span><FaChevronRight size="14" />
            //         </div >
            //     )
            //     console.log('enableStreak', !props.listgroup.length && forecastGroup);
            // }
        }
    }
    

    var showLiveOrNot = ''
    if (props.match.live) {
        showLiveOrNot = <p className="widget-matchpredict__live"><span></span>En Vivo</p>
    }

    return (

        <div key={props.index} id={`match-${props.match.id_match}_${props.index}`} className={`widget-default widget-matchpredict ${classStatusMatch}`}>
            {(showPoints) ?
                <span className="widget-matchpredict__points">{props.match.user_points} pts.</span>
                :
                showLiveOrNot
            }

            {Show &&
                <Form id={`${props.match.id_match}`} autoComplete="off" >
                    <Row>
                        <Col md="12" xs="12" className="widget-matchpredict__date">
                            <span>{props.match.match_date}, {props.match.match_time}hrs</span>
                        </Col>
                        <Col md="5" xs="4" className="widget-matchpredict__local">
                            <img src={props.match.home_image} alt={props.match.home} />
                            <h6>{props.match.home_codename}</h6>
                        </Col>

                        <Col md="2" xs="4" className="widget-matchpredict__guess" data-match-id={props.match.id_match} data-match-text={props.match.match}>
                            <div className="user-input">
                            <Input
                                type="tel"
                                min="0"
                                maxLength="1"
                                name="input-home"
                                placeholder=""
                                onClick={props.handleFocus}
                                onChange={onkeyup}
                                value={scoreHome.trim()}
                                disabled={(inputsDisabled) && true}
                                id={`home_${props.match.id_match}`}
                            />
                            <Input
                                type="tel"
                                min="0"
                                maxLength="1"
                                name="input-away"
                                placeholder=""
                                onClick={props.handleFocus}
                                onChange={onkeyup}
                                value={scoreAway.trim()}
                                disabled={(inputsDisabled) && true}
                                id={`away_${props.match.id_match}`}
                            />
                            </div>
                            {(props.match.match_result) &&
                                <div md="12" xs="12" className="widget-matchpredict__result">
                                    <small>Resultado </small>
                                    <span>{props.match.match_result}</span>
                                </div>
                            }
                        </Col>
                        <Col md="5" xs="4" className="widget-matchpredict__away">
                            <img src={props.match.away_image} alt={props.match.away} />
                            <h6>{props.match.away_codename}</h6>
                        </Col>

                    </Row>
                </Form>


            }
            {(showLoading) ?
                <span className={`widget-matchpredict__feedback`}></span>
                :
                ''
            }
            {(showError || errorFront) ?
                <span className={`widget-matchpredict__error`}>{showMsgError}</span>
                :
                ''
            }

            {
                (!props.listgroup.length && forecastGroup) ?
                    enablePostorDeathMatch
                    :
                    enableStreak
            }
        </div>
    )
}

export default SingleMatch;