import React, { useState, useEffect } from 'react';
import useReactRouter from 'use-react-router';

import { Container, Spinner, Alert, Button, FormGroup, Label, Input, Col } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux'
import './styles/OutstandingGroups.css';

import CountDown from '../components/CountDown/CountDown'
import { validationHandleError, customError } from '../store/actions/utils'
import { updRequest, getUserGroups } from '../store/actions/user'
import { FaUsers } from "react-icons/fa";


import api from '../api'
var interval = null
var intervalJoin = null
const OutstandingGroups = props => {

  const { match, history } = useReactRouter();
  const [grupoInfo, setGrupoInfo] = useState()
  const [loading, setLoading] = useState(true)
  const [btnMessage, setBtnMessage] = useState('Ingresar!')
  const [btnDisable, setBtnDisable] = useState(false)
  const [checkBoxStat, setCheckBoxStat] = useState(false)
  const [inputMessage, setInputMessage] = useState()
  const [showAlert, setShowAlert] = useState(false)
  const [clearIntervalFlag, setClearIntervalFlag] = useState(false)
  const [showCountDown, setShowCountDown] = useState(true)

  const dispatch = useDispatch()

  const objReducer = useSelector(state => {
    return {
      idUser: state.user.id,
      groups: state.user.groups
    }
  })

  useEffect(() => {

    if (match.params.id > 0) {
      setLoading(true)

      api.groups.get(match.params.id).then(data => {

        if (data.status) {
          // data.result.quota_famous = 100
          // data.result.members = 90
          // data.result.start_famous = "2018-01-01 20:00:00"
          // data.result.active_famous = 1
          setGrupoInfo(data.result)
        }
        setLoading(false)
      }).catch(e => {
        setLoading(false)
        history.replace('/')
      })
    }
    // return () => {
    //   cleanup
    // };

  }, [match.params.id, history])

  useEffect(() => {
    if (clearIntervalFlag) {
      clearInterval(interval)
      setClearIntervalFlag(false)
    }

    return () => {
      clearInterval(interval)
      clearInterval(intervalJoin)
    };

  }, [clearIntervalFlag])

  const onFinished = () => {
    setBtnMessage("Finalizado")
    setBtnDisable(true)
    setShowCountDown(false)
  }

  const clickHandler = () => {
    setInputMessage(false)
    if (btnDisable) {

      setShowAlert({ color: "warning", message: "Botton desabilitado" })
    } else {
      if (!checkBoxStat) {
        setInputMessage('Tienes que aceptar los terminos y condiciones')
      } else {
        joinGroup()
      }
    }
  }

  const updateScroll = () => {
    var element = document.getElementById("terminos");
    let elementPosition = element.scrollTop

    let elementHeight = element.scrollHeight
    let adder = elementHeight / 12
    let position = 0
    if (elementPosition < 200) {
      interval = setInterval(() => {
        position = position + adder
        element.scrollTo(0, position)

        if (position >= elementHeight) {
          setClearIntervalFlag(true)
        }
      }, 100);
    }
  }

  const joinGroup = async () => {
    try {
      let data = await api.groups.join({ id_user: objReducer.idUser }, grupoInfo.id_group)
      if (data.statusCode !== 200 && data.statusCode !== 201) {
        customError.prototype = Error.prototype;
        const message = (data.message || data.result.message)
        // eslint-disable-next-line
        throw { name: (data.error || ''), message: message, status: data.status };
      }
      if (data.status) {

        setShowAlert({ color: 'success', message: "Genial!. ya eres parte del equipo!" })
        dispatch(updRequest({ groupRequest: true }))
        dispatch(getUserGroups())
        let groupEncode = grupoInfo.group.split(' ').join('__')
        intervalJoin = setInterval(() => {
          history.replace(`/grupos/clasificacion/${groupEncode}`, { idGroup: grupoInfo.id_group, title: grupoInfo.group })
        }, 2000);
      }
    } catch (e) {
      validationHandleError(e)
      setShowAlert({ color: 'Danger', message: e.message })
    }
  }

  const checkBoxHandler = () => {
    if (!checkBoxStat) {
      setInputMessage()
      // updateScroll()
    }
    setCheckBoxStat(!checkBoxStat)
  }

  let errorMessage = null
  if (showAlert) {
    errorMessage = (
      <Alert color={showAlert.color}>
        {showAlert.message}
      </Alert>
    )
  }

  if (loading) {
    return <Spinner />
  }
  let hasGroup = null;
  let checkBoxDisabled = false;
  let footerPromotion = <Button color="primary" className="button_outstanding" disabled={btnDisable} onClick={clickHandler}>{btnMessage}</Button>

  hasGroup = objReducer.groups.find(group => {
    if (group.user_status === "Aceptar") {
      return group.id_group === grupoInfo.id_group
    }
  })

  let showCD = showCountDown
  let startDate = new Date(grupoInfo.start_famous)
  let currentDate = new Date()
  //Si el grupo esta desactivado
  if (!grupoInfo.active_famous) {
    footerPromotion = <React.Fragment><p style={{fontWeight: 'bold'}}>Finalizó la fecha de inscripción <span aria-label="img" role="img">🙅‍♂️</span></p> <p>No te des por vencido teté, <br></br>en breve van a haber más!</p></React.Fragment>
    checkBoxDisabled = true
  } else {
    if (startDate < currentDate) {
      showCD = false
      footerPromotion = <React.Fragment><p className="outstanding-ending__message">Finalizo la temporada de incripcion!</p><p>Estate atento para futuros nuevos grupos!</p></React.Fragment>
      checkBoxDisabled = true
    }
    //Si Supero Cuota
    if (grupoInfo.members >= grupoInfo.quota_famous) {
      footerPromotion = <p>Ya completamos el cupo para este grupo! Estate atento para futuros nuevos grupos </p>
      checkBoxDisabled = true
    }

    //Si ya esta en el grupo le quito el boton y le muestro un mensaje de que este atento para emepzar a jugar
    if (hasGroup) {
      footerPromotion = (
        <React.Fragment>
          <p style={{ fontWeight: "bold" }}>Yá estás participando <span aria-label="img" role="img">💪</span></p>
          <p>Ahora sólo tenés que sumar puntos!</p>
          <Button color="primary" className="button_outstanding" onClick={() => { history.push('/pronosticos') }}>Empezar a Pronosticar</Button>
        </React.Fragment>
      )
      checkBoxDisabled = true
    }

    //Estoy en el equipo y ya empezo el torneo!. le digo de ir a pronosticos
    if (hasGroup && startDate < currentDate) {
      footerPromotion = (
        <React.Fragment>
          <p style={{fontWeight:'bold'}}>Arranca la competencia! <span aria-label="img" role="img">👏</span></p> <p> Jugá y sumá puntos para estar en la cima!</p>
          <Button color="primary" className="button_outstanding" onClick={() => { history.push('/pronosticos') }} style={{ fontWeight: "bold" }}>Empezar a Pronosticar</Button>
        </React.Fragment>
      )
      checkBoxDisabled = true
    }
  }

  return (
    <Container className="outstanding">
      <span className="background-effect" style={{ backgroundImage: `url(${grupoInfo.image_famous})` }}></span>
      <h3>{grupoInfo.title_famous}</h3>
      {showCD ? <CountDown endDate={grupoInfo.start_famous} onFinishCd={onFinished} title="Arranca en" /> : null}
      <div className="outstanding-description">
        <p>{grupoInfo.description_famous}</p>
      </div>
      <div className="outstanding-quantity"><FaUsers size={16} />Participando: {parseInt(grupoInfo.members).toLocaleString()} / {parseInt(grupoInfo.quota_famous).toLocaleString()}</div>
      {/* <div className="terminos" id="terminos" dangerouslySetInnerHTML={{ __html: grupoInfo.terms_famous}} /> */}
      <div className="outstanding-terms">
        {
          checkBoxDisabled === false
            ? <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={checkBoxStat} onChange={checkBoxHandler} disabled={checkBoxDisabled} />{' '}
                Acepto Terminos y condiciones
        </Label>
              <div style={{ color: "red", fontSize: ".8rem" }}>{inputMessage}</div>
            </FormGroup>
            : ''
        }
      </div>
      <div className="outstanding_message">{footerPromotion}</div>

      {errorMessage}
    </Container >
  )
}

export default OutstandingGroups;