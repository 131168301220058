import React from 'react';
import './PodiumList.css'
import { Row, Col, Spinner } from 'reactstrap';
import { FaChessKing } from "react-icons/fa";

function PodiumList(props) {
    var full_podium = false
    var array_primary = []
    var array_secondary = []

    if (!props.listpodium) {
        return <Spinner style={{ width: '3rem', height: '3rem' }} />
    }

    if (props.listpodium.length) {
        array_primary.push()
        // eslint-disable-next-line
        props.listpodium.map((ele, index) => {
            if (index === 0) {
                if (props.listpodium.length === 1) {
                    array_primary.push({ ...props.listpodium[0], position: 1 })
                } else {
                    array_primary.push({ ...props.listpodium[1], position: 2 })
                }
            }
            else if (index === 1) {
                array_primary.push({ ...props.listpodium[0], position: 1 })
            }
            else if (index === 2) {
                array_primary.push({ ...props.listpodium[2], position: 3 })
            } else {
                array_secondary.push(ele)
                full_podium = true
            }
        })


    }



    var draw_col = 4
    var class_draw_col = ''
    if (array_primary.length === 1) {
        draw_col = 6
        class_draw_col = 'justify-content-center'
    } else if (array_primary.length === 2) {
        draw_col = 6
    }
    return (
        <React.Fragment>
            {(array_primary.length) &&
                <Row className={`podium ${class_draw_col}`}>
                    {array_primary.map((one, index) => {
                        return (
                            <Col key={index} xs={draw_col} md={draw_col}>
                                <div className="podium-container" >
                                    <div className={`podium__position align-bottom chart-position${one.position}`}>
                                        <div className="podium__position__winner"><FaChessKing /></div>
                                        <div className="podium__avatar"><img src={one.avatar} alt={one.username} /></div>
                                        <span className="podium__name">{one.username}</span>
                                        <span className="podium__points">{one.points} Pts.</span>
                                        <div className="podium__chart">{one.position}</div>
                                    </div>
                                </div>

                            </Col>
                        )
                    })}

                </Row>
            }
            {(full_podium) &&
                <Row>
                    <Col md={12}>
                        <ul className="podium-list__group">
                            <li>
                                <div className="col-2">Pos.</div>
                                <div className="col-8">Usuario</div>
                                <div className="col-2">Pts.</div>
                            </li>
                            {
                                array_secondary.map((two, index) => {
                                    return (
                                        <li key={index}>
                                            <div className="col-2 podium__position">{index + 4}</div>
                                            <div className="podium__user col-8">
                                                <img src={two.avatar} alt={`Avatar ${two.username}`} />
                                                <span>{two.username}</span>
                                            </div >
                                            <div className="podium__points col-2">{two.points}</div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                </Row>
            }
            
        </React.Fragment>
    );

}

export default PodiumList;