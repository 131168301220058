import {
  GET_STATS,
  SET_SESSION,
  CHECK_SESSION,
  LOGOUT, 
  LOADING, 
  CREATE_GROUPS,
  ADD_GROUPS,
  SET_NOTIFICATIONS,
  LEAVE_GROUP,
  UPDATE_REQUEST,
  ERROR 
} from '../types/userTypes'

const INITIAL_STATE = {
  name: "",
  isLoggedIn: false,
  sessionChecked: false,
  groups:[
   
  ],
  notifications:{
    groups: {pendings: []}
  },
  stats: [],
  loading: false,
  requestGroup: true,
  error: {
    hasError: false
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return { 
        ...state, 
        loading: true 
      }
    case ERROR:
      return { 
        ...state, 
        ...action.payload,
        loading: false
      }
    case SET_SESSION: 
      return {
        ...state,
        ...action.payload,
        loading: false,
        isLoggedIn: true,
        sessionChecked: true,
        error:false
      }
    case CHECK_SESSION: 
      return {
        ...state,
        sessionChecked: true,
        error:false,
        loading: false
      }
    case LOGOUT: 
      return {
        ...INITIAL_STATE
      }
    case CREATE_GROUPS: 
      return {
        ...state,
        loading:false,
        ...action.payload,
        error:false
      }
    case ADD_GROUPS: 
      return {
        ...state,
        loading:false,
        ...action.payload,
        error:false
      }
    case SET_NOTIFICATIONS: 
      return {
        ...state,
        loading:false,
        notifications: action.payload,
        error:false
      }
    case LEAVE_GROUP: 
      return {
        ...state,
        loading:false,
        groups: action.payload,
        error:false
      }
    case GET_STATS: 
      return {
        ...state, 
        stats: [...action.payload],
        loading:false,
        error:false
      }
    case UPDATE_REQUEST: 
      return {
        ...state, 
        ...action.payload,
        loading:false,
        error:false
      }
    default: return state;
  }
}