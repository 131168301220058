import {
  GET_RANKING, 
  GET_RANKING_JORNADA, 
  LOADING, 
  ERROR 
} from '../types/rankingTypes'

const INITIAL_STATE = {
  groups: {},
  loading: false,
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return { 
        ...state, 
        loading: true 
      }
    case ERROR:
      return { 
        ...state, 
        error: action.payload,
        loading: false
      }
    case GET_RANKING: 
      return {
        ...state,
        groups:{
          ...state.groups, 
          [`group${action.payload.id_group}`]: {
            global: action.payload
          }
        }, 
        loading:false
      }
    case GET_RANKING_JORNADA: 
      return {
        ...state,
        groups:{
          ...state.groups, 
          [`group${action.payload.id_group}`]: {
            ...state.groups[`group${action.payload.id_group}`],
            stages: {
              ...state.groups[`group${action.payload.id_group}`].stages,
              [`stage${action.payload.stage}`]: action.payload
            }
          }
        }, 
        loading:false
      }
    default: return state;
  }
}